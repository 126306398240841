import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import {
  AssociationKeys,
  ChangeLogsType,
  GuestKeys,
  GUESTS_SEARCH_KEYS_CONFIG,
  RegistrantKeys,
} from '@ag-common-lib/public-api';
import { DocumentWriteAction } from '../../../../../../../../../ag-functions/src/models';

@Component({
  selector: 'ag-shr-guests-change-log',
  templateUrl: './attendee-guests-log-details.component.html',
  styleUrls: ['./attendee-guests-log-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendeeGuestsLogDetailsComponent {
  @Input() changeLogs: Delta;
  @Input() guestName: string;
  @Input() action: DocumentWriteAction;

  protected readonly Array = Array;
  protected readonly ChangeLogsType = ChangeLogsType;
  protected readonly guestsConfig = GUESTS_SEARCH_KEYS_CONFIG;
  protected readonly DocumentWriteAction = DocumentWriteAction;
  protected readonly AssociationKeys = AssociationKeys;
  protected readonly GuestKeys = GuestKeys;
  protected readonly RegistrantKeys = RegistrantKeys;
}
