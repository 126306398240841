import { Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';
import { Lookups, TSHIRT_SIZES_LOOKUP, TShirtSizesKeys } from '@ag-common-lib/public-api';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ChangesHistoryGridService } from '../../../changes-history-grid.service';

@UntilDestroy()
@Component({
  selector: 'ag-shr-change-log-tshirt-size-detail',
  templateUrl: './change-log-tshirt-size.component.html',
  styleUrls: ['./change-log-tshirt-size.component.scss'],
})
export class ChangeLogTshirtSizeComponent {
  @Input() log: Delta;

  protected readonly tShirtSizeKeys = [
    TShirtSizesKeys.tShirtSize,
    TShirtSizesKeys.tShirtSizeOther,
    TShirtSizesKeys.unisexTShirtSize,
    TShirtSizesKeys.unisexTShirtSizeOther,
  ];
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly tShirtSizesLookup = TSHIRT_SIZES_LOOKUP;

  constructor(private changesHistoryGridService: ChangesHistoryGridService) {}

  getLookup(key: TShirtSizesKeys) {
    switch (key) {
      case TShirtSizesKeys.unisexTShirtSize:
      case TShirtSizesKeys.tShirtSize:
        return this.changesHistoryGridService.getLookupByName(Lookups.TShirtSize);

      default:
        return null;
    }
  }
}
