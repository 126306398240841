import { DocumentWriteAction } from '../models';
import { ChangeSourceType } from 'ag-common-lib/lib/lists/change-logs/change-source-type.enum';

export enum LoggedCollections {
  agent = 'agent',
  registrant = 'registrant',
  registrantGuest = 'registrantGuest',
}

export interface DocumentLog {
  collection: LoggedCollections;
  action: DocumentWriteAction;
  wasabiPath: string;
  date: string;
  updatedBy: string;
  updatedFields?: string[];
  changedBySource?: ChangeSourceType;
  documentIds: {
    [key: string]: string;
  };
}
