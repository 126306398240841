import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DxDropDownBoxComponent, DxTextBoxComponent, DxTreeListComponent } from 'devextreme-angular';
import { EnterKeyEvent, KeyDownEvent } from 'devextreme/ui/text_box';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { debounceTime, filter, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AutocompleteService } from './address-autocomplete.model';
import { AddressAutocompleteService } from './address-autocomplete.service';
import { Address, AddressModelKeys } from '@ag-common-lib/public-api';
import { LabelMode } from 'devextreme/common';
import { InitializedEvent } from 'devextreme/ui/drop_down_box';
import { ItemClickEvent } from 'devextreme/ui/list';

@UntilDestroy()
@Component({
  selector: 'ag-shr-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
  providers: [AddressAutocompleteService],
})
export class AddressAutocompleteComponent implements OnInit, AfterViewInit, OnChanges {
  @HostBinding('class') className = 'address-autocomplete';

  @ViewChild('dropDownBox') dropDownBoxComponent: DxDropDownBoxComponent | undefined;
  @ViewChild('textBoxRef', { static: false }) textBoxComponent: DxTextBoxComponent;
  @ViewChild('treeListRef', { static: false }) treeListComponent: DxTreeListComponent;

  @Input() service(data: AutocompleteService = AutocompleteService.google) {
    this.addressAutocompleteService.setAutocompleteService(data);
  }
  @Input() extraButtons: any[];
  @Input() isReadonly: boolean = false;
  @Input() notValidated: boolean = false;
  @Input() validationGroup: string;
  @Input() label: string = ' Address 1';
  @Input() labelMode: LabelMode = 'floating';
  @Input() value: string;

  @Output() valueChange = new EventEmitter<string>();
  @Output() addressSelectionChange = new EventEmitter();
  @Output() customAddressCreated = new EventEmitter<string>();

  protected isSuggestionsFetch$: Observable<boolean>;
  protected listItem$: Observable<google.maps.places.AutocompletePrediction[]>;

  private term$ = new Subject<string>();
  protected focusStateEnabled = false;

  constructor(private addressAutocompleteService: AddressAutocompleteService) {
    this.listItem$ = this.term$.pipe(
      filter(() => !environment.production),
      debounceTime(700),
      switchMap(this.addressAutocompleteService.getSuggestions),
      tap(() => {
        this.dropDownBoxComponent?.instance.open();
      }),
      shareReplay(1),
    );
    this.isSuggestionsFetch$ = this.addressAutocompleteService.inProgress$;
  }

  ngOnInit() {
    this.term$
      .pipe(
        tap(term => {
          this.customAddressCreated.emit(term);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.normalizeDropDownButtonState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.normalizeDropDownButtonState();
    }
  }

  protected handleCustomValue = async (e: EnterKeyEvent) => {
    const value = e.component.option('value');
    this.dropDownBoxComponent.instance.option('value', value);
    this.textBoxComponent.instance.option('value', value);
    this.valueChange.emit(value);

    this.dropDownBoxComponent?.instance.close();
    this.dropDownBoxComponent?.instance.focus();
  };

  protected handleUserInput = (input: any): void => {
    this.term$.next((input.event.target as HTMLInputElement).value);
  };

  protected onInitialized = (e: InitializedEvent) => {
    setTimeout(() => {
      e.component.repaint();
    }, 700);
  };

  protected handleKeyDown = (e: KeyDownEvent) => {
    if (e.event.key === 'ArrowDown') {
      this.treeListComponent?.instance?.focus();
    }
  };

  protected handleItemClick = (event: ItemClickEvent<google.maps.places.AutocompletePrediction>) => {
    const placeId: string = event.itemData?.place_id;

    this.selectPlace(placeId);
  };

  private selectPlace = async (placeId: string) => {
    const details = await this.addressAutocompleteService.getDetails(placeId);

    this.addressSelectionChange.emit(details);

    this.textBoxComponent.instance.option('value', details?.[AddressModelKeys.address1]);

    this.dropDownBoxComponent?.instance.close();
    this.dropDownBoxComponent?.instance.focus();
  };

  protected handleDropDownOpened = () => {
    setTimeout(() => {
      this.focusStateEnabled = true;
    }, 500);
  };

  protected handleDropDownClosed = () => {
    this.focusStateEnabled = false;

    const textboxValue = this.textBoxComponent.instance.option('value');
    if (this.value !== textboxValue) {
      this.dropDownBoxComponent.instance.option('value', textboxValue);
      this.textBoxComponent.instance.option('value', textboxValue);
      this.valueChange.emit(textboxValue);
    }
  };

  private normalizeDropDownButtonState = () => {
    if (this.value) {
      setTimeout(() => {
        const dropdownButtonElement = this.dropDownBoxComponent?.instance?.element();
        dropdownButtonElement?.classList?.remove('dx-texteditor-empty');
      }, 0);
    }
  };
}
