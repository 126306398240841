import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { HOTEL_BOOKING_INFO_KEYS_CONFIG } from '@ag-common-lib/public-api';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';

@Component({
  selector: 'ag-shr-change-log-booking-info',
  templateUrl: './change-log-booking-info.component.html',
  styleUrls: ['./change-log-booking-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogBookingInfoComponent {
  @Input() log: Delta;
  protected readonly hasLog = hasLog;
  protected readonly normalizeLog = normalizeLog;
  protected readonly hotelBookingInfoLookup = HOTEL_BOOKING_INFO_KEYS_CONFIG;
}
