import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import {
  ChangeLogsDataType,
  ChangeLogsType,
  Constants,
  LookupBase,
  Lookups,
  TRUE_FALSE_LOOKUP,
} from '@ag-common-lib/public-api';
import { isBoolean, isNil } from 'lodash';

@Component({
  selector: 'ag-shr-change-log-detail',
  templateUrl: './change-log-detail.component.html',
  styleUrls: ['./change-log-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogDetailComponent {
  @Input()
  set changeLog(log: Delta) {
    this.normalizeChangeLog(log);
  }

  @Input() item: string;
  @Input() type: ChangeLogsType = ChangeLogsType.changed;
  @Input() valueLookup: LookupBase[] = null;
  @Input() activeLookupName: Lookups = null;
  @Input() dataType: ChangeLogsDataType = null;

  fromValue = null;
  toValue = null;

  protected readonly ChangeLogsDataType = ChangeLogsDataType;
  protected readonly ChangeLogsType = ChangeLogsType;
  protected readonly dateFormat = Constants.DISPLAY_DATE_FORMAT;

  normalizeChangeLog(log: Delta) {
    if (!isBoolean(log) && !log) {
      return null;
    }
    const registrationData = Array.isArray(log) ? log : [log];
    const leftData = this.normalizeData(registrationData[0]);
    const rightData = this.normalizeData(registrationData[1]);
    this.toValue = registrationData?.length === 1 ? leftData : rightData;
    this.fromValue = registrationData?.length === 1 ? null : leftData;
  }

  private normalizeData = data => {
    if (isNil(data)) {
      return null;
    }

    if (typeof data === 'string' && data.trim().length === 0) {
      return '—'; // for empty string
    }

    if (typeof data == 'boolean') {
      return data.toString();
    }

    return data;
  };

  getBooleanLookupDescription = (val: string): string =>
    val ? TRUE_FALSE_LOOKUP.find(item => item.value.toString() === val).description : '—';
}
