import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';
import {
  DIETARY_CONSIDERATION_LOOKUP,
  DietaryConsiderationKeys,
  Lookups,
  YES_NO_LOOKUP,
} from '@ag-common-lib/public-api';
import { of } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ChangesHistoryGridService } from '../../../changes-history-grid.service';

@UntilDestroy()
@Component({
  selector: 'ag-shr-change-log-dietary-consideration-detail',
  templateUrl: './change-log-dietary-consideration.component.html',
  styleUrls: ['./change-log-dietary-consideration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogDietaryConsiderationComponent {
  @Input() log: Delta;

  protected readonly dietaryConsiderationKeys = [
    DietaryConsiderationKeys.hasDietaryOrPersonalConsideration,
    DietaryConsiderationKeys.dietaryConsiderationType,
    DietaryConsiderationKeys.dietaryConsideration,
  ];
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly dietaryConsiderationLookup = DIETARY_CONSIDERATION_LOOKUP;

  constructor(private changesHistoryGridService: ChangesHistoryGridService) {}

  getLookup(key: DietaryConsiderationKeys) {
    switch (key) {
      case DietaryConsiderationKeys.hasDietaryOrPersonalConsideration:
        return of(YES_NO_LOOKUP);

      case DietaryConsiderationKeys.dietaryConsiderationType:
        return this.changesHistoryGridService.getLookupByName(Lookups.DietaryConsiderationType);

      default:
        return null;
    }
  }
}
