<ng-container *ngFor="let key of associationKeys">
  <ng-container [ngSwitch]="key">
    <ng-container *ngSwitchCase="AssociationKeys.dietaryConsideration">
      <ag-shr-change-log-dietary-consideration-detail
        *ngIf="log?.[key] as item"
        [log]="item"
      ></ag-shr-change-log-dietary-consideration-detail>
    </ng-container>

    <ng-container *ngSwitchCase="AssociationKeys.tShirtSizes">
      <ag-shr-change-log-tshirt-size-detail
        *ngIf="log?.[key] as item"
        [log]="item"
      ></ag-shr-change-log-tshirt-size-detail>
    </ng-container>

    <ng-container *ngSwitchCase="AssociationKeys.address">
      <ag-shr-change-log-address-detail *ngIf="log?.[key] as item" [log]="item"></ag-shr-change-log-address-detail>
    </ng-container>

    <ng-container *ngSwitchCase="AssociationKeys.emailAddress">
      <ag-shr-change-log-email-detail *ngIf="log?.[key] as item" [log]="item"></ag-shr-change-log-email-detail>
    </ng-container>

    <ng-container *ngSwitchCase="AssociationKeys.dob">
      <u>Date Of Birth</u>
      <ng-container *ngIf="log?.[key]?.length === 2; else removedLog">
        was changed from {{ log?.[key]?.[0] | date : dateFormat | empty }} to {{ log[key][1] | date : dateFormat | empty }}
      </ng-container>

      <ng-template #removedLog>
        <ng-container *ngIf="log?.[key]?.length === 3; else addedLog">
          was removed.
        </ng-container>

        <ng-template #addedLog>
          was set as {{ log?.[key]?.[0] | date : dateFormat | empty }}.
        </ng-template>
      </ng-template>

    </ng-container>

    <ng-container *ngSwitchDefault>
      <div
        *ngIf="!!log && hasLog(key, log)"
        [innerHTML]="normalizeLog(key, log) | changeLogsDiffHTML : associationKeysLookup : (getLookup(key) | async)"
      ></div>
    </ng-container>
  </ng-container>
</ng-container>
