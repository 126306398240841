import { Delta } from 'jsondiffpatch/lib/types';
import { isNil } from 'lodash';

export const normalizeLog = (key, value: Delta) => ({ [key]: value[key] });
export const hasLog = (key, value: Delta) => !!key && Object.keys(value)?.some(id => id === key);
export const logHasKey = (key, value) => !!key && value?.hasOwnProperty(key);

export const getChangedLog = log => {
  if (!Array.isArray(log)) {
    return log;
  }
  return log.length == 2 && isNil(log[0]) ? log[1] : log[0];
};

export const getDateTimeLog = dateTimeValue => {
  return !Array.isArray(dateTimeValue)
      ? dateTimeValue.__time__
      : [dateTimeValue[0]?.__time__ ?? null, dateTimeValue[1]?.__time__ ?? null];
};

export const getLogIndexes = logs => {
  return logs.filter(item => item[0] !== '_t').map((item, index) => item[0] ?? index);
};