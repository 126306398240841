import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { ASSOCIATION_KEYS_LOOKUP, AssociationKeys } from '@ag-common-lib/lib/models/utils/association.model';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';
import { TRUE_FALSE_LOOKUP } from '@ag-common-lib/lib/lists/yes-no.list';
import { Constants } from '@ag-common-lib/lib/constants/validation.constants';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Lookups } from '@ag-common-lib/lib/lists/lookups.enum';
import { of } from 'rxjs';
import { ChangesHistoryGridService } from '../../../changes-history-grid.service';

@UntilDestroy()
@Component({
  selector: 'ag-shr-change-log-association-detail',
  templateUrl: './change-log-association.component.html',
  styleUrls: ['./change-log-association.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogAssociationComponent {
  @Input() log: Delta;

  protected readonly associationKeys = [
    AssociationKeys.prefix,
    AssociationKeys.firstName,
    AssociationKeys.middleName,
    AssociationKeys.lastName,
    AssociationKeys.suffix,
    AssociationKeys.passportFirstName,
    AssociationKeys.passportLastName,
    AssociationKeys.dietaryConsideration,
    AssociationKeys.tShirtSizes,
    AssociationKeys.address,
    AssociationKeys.emailAddress,
    AssociationKeys.contactNumber,
    AssociationKeys.isEmergencyContact,
    AssociationKeys.gender,
    AssociationKeys.dob,
    AssociationKeys.associationType,
  ];
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly associationKeysLookup = ASSOCIATION_KEYS_LOOKUP;
  protected readonly trueFalseLookup = TRUE_FALSE_LOOKUP;
  protected readonly AssociationKeys = AssociationKeys;
  protected readonly dateFormat = Constants.DISPLAY_DATE_FORMAT;

  constructor(private changesHistoryGridService: ChangesHistoryGridService) {}

  getLookup(key: AssociationKeys) {
    switch (key) {
      case AssociationKeys.isEmergencyContact:
        return of(this.trueFalseLookup);

      case AssociationKeys.prefix:
        return this.changesHistoryGridService.getLookupByName(Lookups.Prefixes);

      case AssociationKeys.associationType:
        return this.changesHistoryGridService.getLookupByName(Lookups.AssociationType);

      case AssociationKeys.suffix:
        return this.changesHistoryGridService.getLookupByName(Lookups.Suffixes);

      case AssociationKeys.gender:
        return this.changesHistoryGridService.getLookupByName(Lookups.Genders);

      default:
        return null;
    }
  }
}
