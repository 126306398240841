import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogDetailModule } from '../change-log-detail/change-log-detail.module';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';
import { ChangeLogDetailByTypeModule } from '../change-log-detail-by-type/change-log-detail-by-type.module';
import { AgentInfoLogDetailsComponent } from './agent-info-log-details.component';
import { AgentChangeLogDetailsByTypeModule } from './agent-change-log-details-by-type/agent-change-log-details-by-type.module';
import { ChangeLogAddressModule } from '../change-log-detail/address/change-log-address.module';

@NgModule({
  declarations: [AgentInfoLogDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    ChangeLogDetailModule,
    ChangeLogPipesModule,
    ChangeLogDetailByTypeModule,
    AgentChangeLogDetailsByTypeModule,
    ChangeLogAddressModule,
  ],
  exports: [AgentInfoLogDetailsComponent],
})
export class AgentInfoLogDetailsModule {}
