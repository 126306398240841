import { BaseModel } from '../base.model';
import { Address } from '../utils/address.model';
import { RegistrantField } from './registrant-field.model';

import { GuestRate } from './guest-rates.model';
import { Excursion } from './excursion.model';
import { LookupKeys } from '../crm/lookup.model';
import { ConferencePossibleStayPeriod } from './conference-stay-period.model';
import { LocalDateTimeString } from '../utils/local-date-time-string.model';
import { EmailRecipient } from '../utils/email-address.model';
import { Headshot } from '../utils/headshot.model';
import {
  ConferenceStepsConfiguration,
  ConferenceStepsConfigurationObject,
} from './conference-steps-configuration.model';
import { EmailTemplates } from '../functions';

export enum ConferenceType {
  online = 'online',
  offline = 'offline',
}

export const CONFERENCE_TYPE_LOOKUP = [
  { [LookupKeys.value]: ConferenceType.online, [LookupKeys.description]: 'Online conference' },
  { [LookupKeys.value]: ConferenceType.offline, [LookupKeys.description]: 'Onsite event' },
];

export enum HotelRegion {
  domestic = 'domestic',
  international = 'international',
}

export const HOTEL_REGION_LOOKUP = [
  { [LookupKeys.value]: HotelRegion.domestic, [LookupKeys.description]: 'Domestic' },
  { [LookupKeys.value]: HotelRegion.international, [LookupKeys.description]: 'International' },
];

export enum ConferenceKeys {
  eventId = 'event_id',
  eventName = 'event_name',
  eventAddress = 'event_address',
  eventCoordinatorFirstName = 'event_coordinator_first_name',
  eventCoordinatorLastName = 'event_coordinator_last_name',
  eventCoordinatorPhone = 'event_coordinator_phone',
  eventCoordinatorEmail = 'event_coordinator_email',

  startDate = 'start_date',
  endDate = 'end_date',
  registrationStartDate = 'registration_start_date',
  registrationEndDate = 'registration_end_date',

  registrantFields = 'registrantFields',
  facilityName = 'facility_name',
  conferenceType = 'conferenceType',

  bccEmail = 'bccEmail',
  summaryEmailTemplate = 'summaryEmailTemplate',

  activePersonalInfoSections = 'activePersonalInfoSections',

  hotelName = 'hotel_name',
  hotelState = 'hotel_state',
  hotelRegion = 'hotelRegion',
  hotelAddress = 'hotel_address',
  hotelCoordinatorFirstName = 'hotel_coordinator_first_name',
  hotelCoordinatorLastName = 'hotel_coordinator_last_name',
  hotelCoordinatorEmail = 'hotel_coordinator_email',
  hotelPrimaryPhone = 'hotel_primary_phone',
  hotelRoomRate = 'hotel_room_rate',
  hotelNumRoomsBlocked = 'hotel_num_rooms_blocked',

  printMarketingBudget = 'print_marketing_budget',
  foodBudget = 'food_budget',
  excursionsBudget = 'excursions_budget',
  excursionsBookingTime = 'excursionsBookingTime',

  eventEnabled = 'eventEnabled',
  embeddedFormEnabled = 'embeddedFormEnabled',
  eventActive = 'eventActive',
  hotelEnabled = 'hotelEnabled',
  hotelActive = 'hotelActive',
  guestsEnabled = 'guestsEnabled',
  guestsActive = 'guestsActive',
  excursionsEnabled = 'excursionsEnabled',
  excursionsActive = 'excursionsActive',

  excursions = 'excursions',
  maxGuests = 'maxGuests',
  complimentaryGuests = 'complimentaryGuests',
  guestRates = 'guestRates',
  roomsOccupancyHtmlContent = 'roomsOccupancyHtmlContent',

  flightAgentName = 'flightAgentName',

  participantScheduleRanges = 'participantScheduleRanges',

  welcomeDescription = 'welcomeDescription',
  welcomeTitle = 'welcomeTitle',
  welcomeImagePath = 'welcomeImagePath',

  stepsConfiguration = 'stepsConfiguration',
  stepsConfigurationObject = 'stepsConfigurationObject',

  permissionConfigurationIds = 'permissionConfigurationIds',
}

export class Conference extends BaseModel {
  // Welcome Info
  [ConferenceKeys.welcomeTitle]?: string;
  [ConferenceKeys.welcomeDescription]?: string;
  [ConferenceKeys.welcomeImagePath]?: Headshot;

  // Steps Configuration
  /**
   * @deprecated Use stepsConfigurationObject class instead
   */
  [ConferenceKeys.stepsConfiguration]?: ConferenceStepsConfiguration[]; // TODO convert to the class instead of an array
  [ConferenceKeys.stepsConfigurationObject]?: ConferenceStepsConfigurationObject;

  // Primary Info
  [ConferenceKeys.startDate]?: LocalDateTimeString;
  [ConferenceKeys.endDate]?: LocalDateTimeString;
  [ConferenceKeys.registrationStartDate]?: LocalDateTimeString;
  [ConferenceKeys.registrationEndDate]?: LocalDateTimeString;
  [ConferenceKeys.eventId]?: string;
  [ConferenceKeys.eventName]?: string;
  [ConferenceKeys.facilityName]?: string;
  [ConferenceKeys.conferenceType]?: ConferenceType;
  [ConferenceKeys.activePersonalInfoSections]?: string[];

  // Email notification configuration
  [ConferenceKeys.bccEmail]?: EmailRecipient[] = [];
  [ConferenceKeys.summaryEmailTemplate]?: EmailTemplates = EmailTemplates.notifyEmailConferenceRegistrationSummary;

  // Hotel Info
  [ConferenceKeys.hotelEnabled]?: boolean = false;
  [ConferenceKeys.hotelActive]?: boolean = false;
  [ConferenceKeys.hotelName]?: string; // need to add to the hotel section
  [ConferenceKeys.hotelState]?: string; // need to add to the hotel section
  [ConferenceKeys.hotelRegion]?: HotelRegion;
  [ConferenceKeys.hotelAddress]?: Address;
  [ConferenceKeys.hotelCoordinatorFirstName]?: string;
  [ConferenceKeys.hotelCoordinatorLastName]?: string;
  [ConferenceKeys.hotelPrimaryPhone]?: string;
  [ConferenceKeys.hotelCoordinatorEmail]?: string;
  [ConferenceKeys.hotelRoomRate]?: number;
  [ConferenceKeys.hotelNumRoomsBlocked]?: number;
  [ConferenceKeys.participantScheduleRanges]?: ConferencePossibleStayPeriod[] = [];

  // Guests Info
  [ConferenceKeys.guestsEnabled]?: boolean = false;
  [ConferenceKeys.guestsActive]?: boolean = false;
  [ConferenceKeys.maxGuests]?: number = null;
  [ConferenceKeys.complimentaryGuests]?: number = null;
  [ConferenceKeys.guestRates]?: GuestRate[] = [];
  [ConferenceKeys.roomsOccupancyHtmlContent]?: string;

  // Excursions
  [ConferenceKeys.excursionsBudget]?: number = null;
  [ConferenceKeys.excursionsBookingTime]?: number = 15;
  [ConferenceKeys.excursionsEnabled]?: boolean = false;
  [ConferenceKeys.excursionsActive]?: boolean = false;
  [ConferenceKeys.excursions]?: Excursion[] = [];

  // Embedded Form
  [ConferenceKeys.embeddedFormEnabled]?: boolean = false;
  // Permission Configuration
  [ConferenceKeys.permissionConfigurationIds]?: string[];

  [ConferenceKeys.flightAgentName]?: string;
  // Legacy

  [ConferenceKeys.eventCoordinatorFirstName]?: string;
  [ConferenceKeys.eventCoordinatorLastName]?: string;
  [ConferenceKeys.eventCoordinatorPhone]?: string;
  [ConferenceKeys.eventCoordinatorEmail]?: string;
  [ConferenceKeys.eventEnabled]?: boolean;
  [ConferenceKeys.eventActive]?: boolean;
  [ConferenceKeys.eventAddress]?: Address;
  [ConferenceKeys.printMarketingBudget]?: number;
  [ConferenceKeys.foodBudget]?: number;
  [ConferenceKeys.registrantFields]?: RegistrantField[] = [];
}
