import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { FLIGHT_INFO_KEYS_CONFIG, FlightInfoKeys, LookupBase } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-flight-info-change-log',
  templateUrl: './attendee-flight-info-log-details.component.html',
  styleUrls: ['./attendee-flight-info-log-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendeeFlightInfoLogDetailsComponent {
  @Input() changeLogs: Delta;
  @Input() attendeeName: string;
  @Input() agentsLookup: LookupBase[];

  protected readonly flightInfoKeysConfig = FLIGHT_INFO_KEYS_CONFIG;
  protected readonly FlightInfoKeys = FlightInfoKeys;
}
