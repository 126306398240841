import { ChangeLogsConfig, ChangeLogsConfigKey, ChangeLogsDataType } from '../../models/registration/change-logs.model';
import {
  INVITEE_AGE_GROUP_LOOKUP,
  INVITEE_OUTCOME_STATUS_LOOKUP,
  INVITEE_STATUS_LOOKUP,
  RegistrantKeys,
  RESERVATION_STATUS_LOOKUP,
} from '../../models/registration/registrants.model';
import { Lookups } from '../lookups.enum';
import { REGISTRATION_TYPE_LOOKUP } from '../../models/registration/conference-registration-type.model';
import { QUALIFIED_AS_LOOKUP } from '../../models/registration/registrant-qualifying.model';
import { REGISTRANTS_TITLE_LIST } from '../registrants.config';
import {
  HOTEL_RESERVATION_KEYS_LOOKUP,
  HotelBookingKeys,
  HotelReservationKeys,
} from '../../models/registration/hotel-reservation.model';
import { ExcursionOptionKey, SelectedExcursionsKeys } from '../../models/registration/excursion.model';
import {
  FLIGHT_BOOKING_STATUS_LOOKUP,
  FLIGHT_DIRECTION_LOOKUP,
  FLIGHT_INFO_KEYS_LOOKUP,
  FlightBookingKeys,
  FlightInfoKeys,
  SEAT_PREFERENCE_LOOKUP,
} from '../../models/registration/flight-information.model';
import { ADDRESS_LOOKUP, AddressModelKeys } from '../../models/utils/address.model';
import { ASSOCIATION_KEYS_LOOKUP, AssociationKeys } from '../../models/utils/association.model';
import { GUEST_TITLE_LIST, GuestKeys } from '../../models/registration/guest.model';
import { createLookupWithCategory } from './change-logs-config.utils';

function createChildsSearchLookupWithCategory() {
  const filterValues = [];
  changeLogSearchFieldsMap.forEach((value, key) => {
    filterValues.push({
      value: key,
      description: value,
      category: value.split(':')[0],
    });
  });
  return filterValues;
}

const getFlightInformationConfig = (): ChangeLogsConfig[] => {
  const flightInfoLookup = FLIGHT_INFO_KEYS_LOOKUP;
  const flightInfoConfig = [];
  flightInfoLookup.forEach(item => {
    const key = item.value;
    const config = {
      value: [RegistrantKeys.flightInformation, key].join('.'),
      description: item.description ?? key,
    };
    switch (key) {
      case FlightInfoKeys.prefix:
        config[ChangeLogsConfigKey.activeLookupName] = Lookups.Prefixes;
        break;

      case FlightInfoKeys.suffix:
      case FlightInfoKeys.driverLicenseSuffix:
        config[ChangeLogsConfigKey.activeLookupName] = Lookups.Suffixes;
        break;

      case FlightInfoKeys.sameForAll:
      case FlightInfoKeys.travelDatesDiffer:
      case FlightInfoKeys.passportConfirmation:
      case FlightInfoKeys.passportNameConfirmation:
      case FlightInfoKeys.driverLicenseNameConfirmation:
      case FlightInfoKeys.isBookOwnFlights:
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.boolean;
        break;

      case FlightInfoKeys.dateSent:
      case FlightInfoKeys.arrivalDate:
      case FlightInfoKeys.departureDate:
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.localDateTimeString;
        break;

      case FlightInfoKeys.seatPreference:
        config[ChangeLogsConfigKey.valueLookup] = SEAT_PREFERENCE_LOOKUP;
        break;

      case FlightInfoKeys.status:
        config[ChangeLogsConfigKey.valueLookup] = FLIGHT_BOOKING_STATUS_LOOKUP;
        break;
    }
    flightInfoConfig.push(config);
  });

  return flightInfoConfig;
};

const getFlightBookingConfig = (): ChangeLogsConfig[] => {
  const flightBookingKeys = Object.values(FlightBookingKeys).filter(key => ![FlightBookingKeys.id].includes(key));
  const flightBookingConfig = [];
  flightBookingKeys.forEach(key => {
    const config = {
      value: [RegistrantKeys.flightInformation, key].join('.'),
    };
    switch (key) {
      case FlightBookingKeys.directionType:
        config[ChangeLogsConfigKey.description] = 'Flight Direction Type';
        config[ChangeLogsConfigKey.valueLookup] = FLIGHT_DIRECTION_LOOKUP;
        break;

      case FlightBookingKeys.airline:
        config[ChangeLogsConfigKey.description] = 'Airline';
        break;

      case FlightBookingKeys.flightNumber:
        config[ChangeLogsConfigKey.description] = 'Flight #';
        break;

      case FlightBookingKeys.confirmationNumber:
        config[ChangeLogsConfigKey.description] = 'Conf #';
        break;

      case FlightBookingKeys.passengerName:
        config[ChangeLogsConfigKey.description] = 'Flight Passenger Name';
        break;

      case FlightBookingKeys.seat:
        config[ChangeLogsConfigKey.description] = 'Flight Seat';
        break;

      case FlightBookingKeys.class:
        config[ChangeLogsConfigKey.description] = 'Flight Class';
        break;

      case FlightBookingKeys.frequentFlyerNumber:
        config[ChangeLogsConfigKey.description] = 'Flight Frequent Flyer Number';
        break;

      case FlightBookingKeys.knownTravelerNumber:
        config[ChangeLogsConfigKey.description] = 'Flight Known Traveler Number';
        break;

      case FlightBookingKeys.departureAirport:
        config[ChangeLogsConfigKey.description] = 'Departure Airport';
        break;
      // TODO update flight date / time
      case FlightBookingKeys.flightDepartureTime:
        config[ChangeLogsConfigKey.description] = 'Flight Departure Time';
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.localDateTimeString;
        break;

      case FlightBookingKeys.arrivalAirport:
        config[ChangeLogsConfigKey.description] = 'Arrival Airport';
        break;

      // TODO update flight date / time
      case FlightBookingKeys.flightArrivalTime:
        config[ChangeLogsConfigKey.description] = 'Flight Arrival Time';
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.localDateTimeString;
        break;

      default:
        config[ChangeLogsConfigKey.description] = key;
        break;
    }
    flightBookingConfig.push(config);
  });

  return flightBookingConfig;
};

const getHotelReservationConfig = (): ChangeLogsConfig[] => {
  const hotelReservationLookup = HOTEL_RESERVATION_KEYS_LOOKUP;
  const hotelReservationConfig = [];

  hotelReservationLookup.forEach(item => {
    const key = item.value;
    const config = {
      value: [RegistrantKeys.hotelReservation, key].join('.'),
      description: item.description ?? key,
    };
    switch (key) {
      case HotelReservationKeys.checkInDate:
      case HotelReservationKeys.checkOutDate:
      case HotelReservationKeys.requestedCheckInDate:
      case HotelReservationKeys.requestedCheckOutDate:
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.localDateTimeString;
        break;

      case HotelReservationKeys.additionalRoomRequestOutcome:
      case HotelReservationKeys.requestDifferBookingDatesOutcome:
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.requestOutcome;
        break;

      case HotelReservationKeys.additionalRoomRequested:
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.boolean;
        break;
    }
    hotelReservationConfig.push(config);
  });

  return hotelReservationConfig;
};

const getAddressConfig = (parentKey: RegistrantKeys): ChangeLogsConfig[] => {
  const addressLookup = ADDRESS_LOOKUP;
  const addressConfig = [];

  addressLookup.forEach(item => {
    const key = item.value;
    const config = {
      value: [parentKey, key].join('.'),
      description: item.description ?? key,
    };
    switch (key) {
      case AddressModelKeys.isPrimaryShipping:
      case AddressModelKeys.isPrimaryBilling:
      case AddressModelKeys.isPhysicalLocation:
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.boolean;
        break;
    }
    addressConfig.push(config);
  });
  return addressConfig;
};

const getEmergencyContactConfig = (): ChangeLogsConfig[] => {
  const emergencyContactLookup = ASSOCIATION_KEYS_LOOKUP;
  const emergencyContactConfig = [];

  emergencyContactLookup.forEach(item => {
    const key = item.value;
    const config = {
      value: [RegistrantKeys.emergencyContact, key].join('.'),
      description: item.description ?? key,
    };
    switch (key) {
      case AssociationKeys.prefix:
        config[ChangeLogsConfigKey.activeLookupName] = Lookups.Prefixes;
        break;

      case AssociationKeys.suffix:
        config[ChangeLogsConfigKey.activeLookupName] = Lookups.Suffixes;
        break;

      case AssociationKeys.gender:
        config[ChangeLogsConfigKey.activeLookupName] = Lookups.Genders;
        break;

      case AssociationKeys.associationType:
        config[ChangeLogsConfigKey.activeLookupName] = Lookups.AssociationType;
        break;

      case AssociationKeys.dietaryConsideration:
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.dietaryConsideration;
        break;

      case AssociationKeys.tShirtSizes:
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.tShirtSizes;
        break;

      case AssociationKeys.address:
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.address;
        break;

      case AssociationKeys.isEmergencyContact:
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.boolean;
        break;

      case AssociationKeys.dob:
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.localDateTimeString;
        break;
    }
    emergencyContactConfig.push(config);
  });
  return emergencyContactConfig;
};

export const titleList = {
  guestRegistration: 'Guest Registration',
  hotelReservation: 'Hotel Reservation',
  flightInformation: 'Flight Information',
  personalInformation: 'Personal Information',
  rsvp: 'RSVP',
};

export const changeLogSearchFieldsMap: Map<string, string> = new Map([
  [
    [RegistrantKeys.hotelReservation, HotelReservationKeys.checkInDate].join('.'),
    `${titleList.hotelReservation}: Check In Date`,
  ],
  [
    [RegistrantKeys.hotelReservation, HotelReservationKeys.checkOutDate].join('.'),
    `${titleList.hotelReservation}: Check Out Date`,
  ],
  [
    [RegistrantKeys.flightInformation, FlightInfoKeys.arrivalDate].join('.'),
    `${titleList.flightInformation}: Arrival Date`,
  ],
  [
    [RegistrantKeys.flightInformation, FlightInfoKeys.departureDate].join('.'),
    `${titleList.flightInformation}: Departure Date`,
  ],
  [
    ['guestRegistration', AssociationKeys.dob].join('.'),
    `${titleList.guestRegistration}: ${GUEST_TITLE_LIST[AssociationKeys.dob]}`,
  ],
  [['guestRegistration', GuestKeys.flightInformation].join('.'), `${titleList.guestRegistration}: Flight Information`],
  [
    ['guestRegistration', GuestKeys.selectedExcursions].join('.'),
    `${titleList.guestRegistration}: Selected Excursions`,
  ],
]);

export const HOTEL_RESERVATION_INFO_KEYS_CONFIG: ChangeLogsConfig[] = getHotelReservationConfig();

export const HOTEL_BOOKING_INFO_KEYS_CONFIG: ChangeLogsConfig[] = [
  {
    value: [RegistrantKeys.hotelReservation, HotelBookingKeys.arrival].join('.'),
    description: 'Hotel Arrival Date',
    dataType: ChangeLogsDataType.localDateTimeString,
  },
  {
    value: [RegistrantKeys.hotelReservation, HotelBookingKeys.departure].join('.'),
    description: 'Hotel Departure Date',
    dataType: ChangeLogsDataType.localDateTimeString,
  },
  {
    value: [RegistrantKeys.hotelReservation, HotelBookingKeys.hotel].join('.'),
    description: 'Hotel',
  },
  {
    value: [RegistrantKeys.hotelReservation, HotelBookingKeys.bookingNumber].join('.'),
    description: 'Hotel Booking Number',
  },
  {
    value: [RegistrantKeys.hotelReservation, HotelBookingKeys.reservationStatus].join('.'),
    description: 'Hotel Reservation Status',
    valueLookup: RESERVATION_STATUS_LOOKUP,
  },
];

export const SHIPPING_ADDRESS_KEYS_CONFIG: ChangeLogsConfig[] = getAddressConfig(RegistrantKeys.shippingAddress);

export const BILLING_ADDRESS_KEYS_CONFIG: ChangeLogsConfig[] = getAddressConfig(RegistrantKeys.billingAddress);

export const EMERGENCY_CONTACT_KEYS_CONFIG: ChangeLogsConfig[] = getEmergencyContactConfig();

export const RSVP_SEARCH_KEYS_CONFIG: ChangeLogsConfig[] = [
  // RSVP Fields
  {
    value: RegistrantKeys.inviteeStatus,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.inviteeStatus],
    valueLookup: INVITEE_STATUS_LOOKUP,
  },
  {
    value: RegistrantKeys.inviteeOutcomeStatus,
    description: 'Invitee Outcome Status',
    valueLookup: INVITEE_OUTCOME_STATUS_LOOKUP,
  },
  {
    value: RegistrantKeys.plaqueRequired,
    description: 'Is Plaque Required',
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: RegistrantKeys.awardName,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.awardName],
  },
  {
    value: RegistrantKeys.isSendPlaque,
    description: 'Is Send Plaque',
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: RegistrantKeys.isSendPlaqueOwner,
    description: 'Is Send Plaque Owner',
  },
  {
    value: RegistrantKeys.isSendPlaqueOwnerNote,
    description: 'Is Send Plaque Owner Note',
  },
];

export const PERSONAL_INFO_SEARCH_KEYS_CONFIG: ChangeLogsConfig[] = [
  // Personal Info Fields
  {
    value: RegistrantKeys.prefix,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.prefix],
    activeLookupName: Lookups.Prefixes,
  },
  {
    value: RegistrantKeys.firstName,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.firstName],
  },
  {
    value: RegistrantKeys.middleName,
    description: 'Middle Name',
  },
  {
    value: RegistrantKeys.lastName,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.lastName],
  },
  {
    value: RegistrantKeys.suffix,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.suffix],
    activeLookupName: Lookups.Suffixes,
  },
  {
    value: RegistrantKeys.badgeName,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.badgeName],
  },
  {
    value: RegistrantKeys.passportFirstName,
    description: 'Passport First Name',
  },
  {
    value: RegistrantKeys.passportLastName,
    description: 'Passport Last Name',
  },
  {
    value: RegistrantKeys.firstTimeAttendee,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.firstTimeAttendee],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: RegistrantKeys.gender,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.gender],
    activeLookupName: Lookups.Genders,
  },
  {
    value: RegistrantKeys.dob,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.dob],
    dataType: ChangeLogsDataType.localDateTimeString,
  },
  {
    value: RegistrantKeys.headshot,
    description: 'Headshot',
    dataType: ChangeLogsDataType.headshot,
  },
  // Personal Info email
  {
    value: RegistrantKeys.primaryEmailAddress,
    description: 'Primary Email Address',
    dataType: ChangeLogsDataType.emailAddress,
  },
  {
    value: RegistrantKeys.ccEmail,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.ccEmail],
  },
  {
    value: RegistrantKeys.inviteeEmail,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.inviteeEmail],
  },
  // Personal Info Address
  {
    value: RegistrantKeys.shippingAddress,
    description: 'Shipping Address',
    dataType: ChangeLogsDataType.address,
  },
  {
    value: RegistrantKeys.isBillingDifferentFromShippingAddress,
    description: 'The Billing Address is different from the Shipping Address.',
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: RegistrantKeys.billingAddress,
    description: 'Billing Address',
    dataType: ChangeLogsDataType.address,
  },
  {
    value: RegistrantKeys.mobilePhone,
    description: 'Mobile Phone',
    dataType: ChangeLogsDataType.phoneNumber,
  },
  {
    value: RegistrantKeys.tShirtSizes,
    dataType: ChangeLogsDataType.tShirtSizes,
    description: 'T-Shirt Size',
  },
  {
    value: RegistrantKeys.dietaryConsideration,
    description: 'Dietary Considerations',
    dataType: ChangeLogsDataType.dietaryConsideration,
  },
  {
    value: RegistrantKeys.emergencyContact,
    description: 'Emergency Contact',
    dataType: ChangeLogsDataType.association,
  },
  //Other
  {
    value: RegistrantKeys.registrationType,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.registrationType],
    valueLookup: REGISTRATION_TYPE_LOOKUP,
  },
  {
    value: RegistrantKeys.qualifiedAs,
    description: REGISTRANTS_TITLE_LIST[RegistrantKeys.qualifiedAs],
    valueLookup: QUALIFIED_AS_LOOKUP,
  },
  {
    value: RegistrantKeys.lastEvalDate,
    description: 'Last Eval Date',
    dataType: ChangeLogsDataType.localDateTimeString,
  },
  // Registrant Guests configuration
  {
    value: RegistrantKeys.additionalGuestRequested,
    description: 'Additional Guest Requested',
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: RegistrantKeys.isGuestsAttended,
    description: 'Is Guests Attended',
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: RegistrantKeys.numberOfGuests,
    description: 'Number of Guests',
  },
  {
    value: RegistrantKeys.additionalGuestRequestOutcome,
    description: 'Additional Guest Request Outcome.',
    dataType: ChangeLogsDataType.requestOutcome,
  },
];

export const ROOT_SEARCH_KEYS_CONFIG: ChangeLogsConfig[] = [
  {
    value: RegistrantKeys.hotelReservation,
    description: 'Hotel Reservation',
  },
  {
    value: RegistrantKeys.selectedExcursions,
    description: 'Selected Excursions',
  },
  {
    value: RegistrantKeys.flightInformation,
    description: 'Flight Information',
  },
  // Guests configuration
  // todo: Guests
  {
    value: 'guestRegistration',
    description: titleList.guestRegistration,
  },
];

export const FLIGHT_INFO_KEYS_CONFIG: ChangeLogsConfig[] = getFlightInformationConfig();

export const FLIGHT_BOOKING_KEYS_CONFIG: ChangeLogsConfig[] = getFlightBookingConfig();

export const SELECTED_EXCURSIONS_KEYS_CONFIG: ChangeLogsConfig[] = [
  {
    value: [RegistrantKeys.selectedExcursions, SelectedExcursionsKeys.bookingDate].join('.'),
    description: 'Excursion Booking Date',
    dataType: ChangeLogsDataType.localDateTimeString,
  },
  {
    value: [RegistrantKeys.selectedExcursions, SelectedExcursionsKeys.isPaid].join('.'),
    description: 'Is Excursion Paid?',
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: [RegistrantKeys.selectedExcursions, SelectedExcursionsKeys.isAdminSelected].join('.'),
    description: 'Is Admin Selected Excursion?',
    dataType: ChangeLogsDataType.boolean,
  },
  // asked to hide preferences and configurations
  // {
  //   value: [RegistrantKeys.selectedExcursions, SelectedExcursionsKeys.preferences].join('.'),
  //   description: 'Excursion Preferences',
  // },
  // {
  //   value: [RegistrantKeys.selectedExcursions, SelectedExcursionsKeys.configurations].join('.'),
  //   description: 'Excursion Configurations',
  // },
];

export const SELECTED_EXCURSION_PREFERENCES_CONFIG: ChangeLogsConfig[] = [
  {
    value: [RegistrantKeys.selectedExcursions, ExcursionOptionKey.name].join('.'),
    description: 'Excursion Name',
  },
  {
    value: [RegistrantKeys.selectedExcursions, ExcursionOptionKey.cost].join('.'),
    description: 'Cost',
  },
  {
    value: [RegistrantKeys.selectedExcursions, ExcursionOptionKey.description].join('.'),
    description: 'Description',
  },
];

export const SELECTED_EXCURSION_CONFIGURATIONS_CONFIG: ChangeLogsConfig[] = [
  {
    value: [RegistrantKeys.selectedExcursions, 'value'].join('.'),
    description: 'This',
  },
  {
    value: [RegistrantKeys.selectedExcursions, 'dataType'].join('.'),
    description: 'Type',
  },
];

export const GUESTS_REGISTRATION_CONFIG: ChangeLogsConfig[] = [
  {
    value: 'guestRegistration',
    description: titleList.guestRegistration,
  },
];

export const GUESTS_REGISTRATION_KEYS_CONFIG: ChangeLogsConfig[] = [
  // Registrant Information
  {
    value: AssociationKeys.prefix,
    description: GUEST_TITLE_LIST[AssociationKeys.prefix],
    activeLookupName: Lookups.Prefixes,
  },
  {
    value: AssociationKeys.firstName,
    description: GUEST_TITLE_LIST[AssociationKeys.firstName],
  },
  {
    value: AssociationKeys.lastName,
    description: GUEST_TITLE_LIST[AssociationKeys.lastName],
  },
  {
    value: AssociationKeys.suffix,
    description: GUEST_TITLE_LIST[AssociationKeys.suffix],
    activeLookupName: Lookups.Suffixes,
  },
  {
    value: AssociationKeys.associationType,
    description: GUEST_TITLE_LIST[AssociationKeys.associationType],
    activeLookupName: Lookups.AssociationType,
  },
  {
    value: AssociationKeys.gender,
    description: GUEST_TITLE_LIST[AssociationKeys.gender],
    activeLookupName: Lookups.Genders,
  },
  {
    value: AssociationKeys.dob,
    description: GUEST_TITLE_LIST[AssociationKeys.dob],
    dataType: ChangeLogsDataType.localDateTimeString,
  },
  {
    value: AssociationKeys.emailAddress,
    description: 'Email Address',
  },
  {
    value: AssociationKeys.contactNumber,
    description: 'Contact Number',
  },
  {
    value: AssociationKeys.address,
    description: 'Address',
    dataType: ChangeLogsDataType.address,
  },
  {
    value: GuestKeys.badgeName,
    description: 'Badge Name',
  },
  {
    value: AssociationKeys.passportFirstName,
    description: 'Passport First Name',
  },
  {
    value: AssociationKeys.passportLastName,
    description: 'Passport Last Name',
  },
  {
    value: AssociationKeys.isEmergencyContact,
    description: 'Is Emergency Contact',
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: GuestKeys.isComplimentary,
    description: 'is Complimentary Guest',
    dataType: ChangeLogsDataType.boolean,
    // valueLookup: GUEST_TYPES_LOOKUP,
  },
  // Event Information
  {
    value: GuestKeys.approved,
    description: 'Approved',
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: GuestKeys.approvedBy,
    description: GUEST_TITLE_LIST[GuestKeys.approvedBy],
  },
  {
    value: GuestKeys.approvedDate,
    description: GUEST_TITLE_LIST[GuestKeys.approvedDate],
    dataType: ChangeLogsDataType.localDateTimeString,
  },
  {
    value: GuestKeys.revokedBy,
    description: 'Revoked By',
  },
  {
    value: GuestKeys.revokedDate,
    description: 'Revoked Date',
    dataType: ChangeLogsDataType.localDateTimeString,
  },
  // Additional Information
  {
    value: GuestKeys.childOrAdult,
    description: 'Child or Adult',
    valueLookup: INVITEE_AGE_GROUP_LOOKUP,
  },
  {
    value: AssociationKeys.tShirtSizes,
    dataType: ChangeLogsDataType.tShirtSizes,
    description: 'T-Shirt Size',
  },
  {
    value: AssociationKeys.dietaryConsideration,
    description: 'Dietary Considerations',
    dataType: ChangeLogsDataType.dietaryConsideration,
  },
  {
    value: GuestKeys.flightInformation,
    description: 'Flight Information',
  },
  {
    value: GuestKeys.selectedExcursions,
    description: 'Selected Excursions',
  },
];

export const GUESTS_SEARCH_KEYS_CONFIG = GUESTS_REGISTRATION_KEYS_CONFIG.filter(item => {
  const excludedKeys: string[] = [GuestKeys.flightInformation, GuestKeys.selectedExcursions];
  return !excludedKeys.includes(item.value);
});

export const rsvpLookup = createLookupWithCategory(RSVP_SEARCH_KEYS_CONFIG, titleList.rsvp);
export const personalInfoLookup = createLookupWithCategory(
  PERSONAL_INFO_SEARCH_KEYS_CONFIG,
  titleList.personalInformation,
);
export const rootSearchLookup = createLookupWithCategory(ROOT_SEARCH_KEYS_CONFIG, titleList.personalInformation);
export const childSearchLookup = createChildsSearchLookupWithCategory();

// Emergency Contact has the same name with Registrant (association.model)
export const changeLogsLookup = [...rsvpLookup, ...personalInfoLookup, ...rootSearchLookup, ...childSearchLookup];
