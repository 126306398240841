import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { hasLog } from '../../utils/normalize-log';
import {
  ChangeLogsType,
  HOTEL_RESERVATION_INFO_KEYS_CONFIG,
  HotelReservationKeys,
  LookupBase,
} from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-hotel-reservation-change-log',
  templateUrl: './attendee-hotel-reservation-log-details.component.html',
  styleUrls: ['./attendee-hotel-reservation-log-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendeeHotelReservationLogDetailsComponent {
  @Input() changeLogs: Delta;
  @Input() attendeeName: string;
  @Input() agentsLookup: LookupBase[];

  protected readonly hotelReservationKeysLookup = HOTEL_RESERVATION_INFO_KEYS_CONFIG;
  protected readonly HotelReservationKeys = HotelReservationKeys;
  protected readonly hasLog = hasLog;
  protected readonly ChangeLogsType = ChangeLogsType;
}
