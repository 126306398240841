import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogExcursionConfigurationsModule } from './configurations/change-log-excursion-configurations.module';
import { AttendeeExcursionsLogDetailsComponent } from './attendee-excursions-log-details.component';
import { ChangeLogExcursionPreferencesModule } from './preferences/change-log-excursion-preferences.module';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';
import { ChangeLogDetailByTypeModule } from '../change-log-detail-by-type/change-log-detail-by-type.module';
import { ChangeLogDetailModule } from '../change-log-detail/change-log-detail.module';

@NgModule({
  declarations: [AttendeeExcursionsLogDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    ChangeLogDetailModule,
    ChangeLogExcursionConfigurationsModule,
    ChangeLogExcursionPreferencesModule,
    ChangeLogPipesModule,
    ChangeLogDetailByTypeModule,
  ],
  exports: [AttendeeExcursionsLogDetailsComponent],
})
export class AttendeeExcursionsLogDetailsModule {}
