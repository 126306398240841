import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ADDRESS_LOOKUP, AddressModelKeys, TRUE_FALSE_LOOKUP } from '@ag-common-lib/public-api';
import { Delta } from 'jsondiffpatch/lib/types';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';

@Component({
  selector: 'ag-shr-change-log-address-detail',
  templateUrl: './change-log-address.component.html',
  styleUrls: ['./change-log-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogAddressComponent {
  @Input() log: Delta;

  protected readonly addressKeys = [
    AddressModelKeys.address1,
    AddressModelKeys.address2,
    AddressModelKeys.city,
    AddressModelKeys.state,
    AddressModelKeys.zip,
    AddressModelKeys.country,
    AddressModelKeys.county,
    AddressModelKeys.addressType,
    AddressModelKeys.isPrimaryShipping,
    AddressModelKeys.isPrimaryBilling,
    AddressModelKeys.isPhysicalLocation,
  ];
  protected readonly addressLookup = ADDRESS_LOOKUP;
  protected readonly trueFalseLookup = TRUE_FALSE_LOOKUP;
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly addressKeyWithLookup = {
    [AddressModelKeys.isPrimaryShipping]: this.trueFalseLookup,
    [AddressModelKeys.isPrimaryBilling]: this.trueFalseLookup,
    [AddressModelKeys.isPhysicalLocation]: this.trueFalseLookup,
  };
}
