import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogDietaryConsiderationComponent } from './change-log-dietary-consideration.component';
import { ChangeLogPipesModule } from '../../../pipes/change-log-pipes.module';

@NgModule({
  declarations: [ChangeLogDietaryConsiderationComponent],
  imports: [CommonModule, SharedModule, ChangeLogPipesModule,],
  exports: [ChangeLogDietaryConsiderationComponent],
})
export class ChangeLogDietaryConsiderationModule {}
