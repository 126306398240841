<h5>Flight Information</h5>

<div class="md-4">
  <ng-container *ngFor="let item of flightInfoKeysConfig">
    <ng-container *var="item.value | changeLogChildKey as key">
      <ng-container *ngIf="changeLogs?.[key] as log">
        <ng-container [ngSwitch]="key">
          <div class="pl-4 ps-4" *ngSwitchCase="FlightInfoKeys.travelDatesDifferRequestOutcome">
            <h6>{{ item.description }}</h6>
            <ag-shr-change-log-request-outcome-detail [log]="log[0]"></ag-shr-change-log-request-outcome-detail>
          </div>

          <div class="pl-4 ps-4" *ngSwitchCase="FlightInfoKeys.bookingInfo">
            <h6>{{ item.description }}</h6>
            <ag-shr-change-log-flight-booking-info
              [logs]="log"
              [attendeeName]="attendeeName"
            ></ag-shr-change-log-flight-booking-info>
          </div>

          <div class="pl-4 ps-4" *ngSwitchDefault>
            <ag-shr-change-log-detail-by-type
              *ngIf="!!log?.length"
              [keysConfig]="[item]"
              [changeLogs]="changeLogs"
            ></ag-shr-change-log-detail-by-type>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
