export enum FunctionsNames {
  sendOTP = 'sendOTP',
  bindUserToAgent = 'bindUserToAgent',
  confirmEmail = 'confirmEmail',
  deleteAgent = 'deleteAgent',
  deleteFirebaseUser = 'deleteFirebaseUser',
  updateUserLoginEmail = 'updateUserLoginEmail',
  findAgentDbIdByLoginEmail = 'findAgentDbIdByLoginEmail',
  updateSalesGoals = 'updateSalesGoals',
  validateOTP = 'validateOTP',
  updateUserPassword = 'updateUserPassword',
  elasticSearch = 'elasticSearch',
  uploadMedia = 'uploadMedia',
  getMedia = 'getMedia',
  deleteMedia = 'deleteMedia',
  getImageContentByUrl = 'getImageContentByUrl',
  updateAgentCampaignsIssuesStatistics = 'updateAgentCampaignsIssuesStatistics',
  getArchivedImages = 'getArchivedImages',
  collectPaymentIntent = 'collectPaymentIntent',
  processPayment = 'processPayment',
  refundPayment = 'refundPayment',
  handleWebhookPayment = 'handleWebhookPayment',
  getPaymentEventListByTypes = 'getPaymentEventListByTypes',
  deleteConferenceRegistration = 'deleteConferenceRegistration',
  copyConferenceRegistration = 'copyConferenceRegistration',
  registrationSummaryEmail = 'registrationSummaryEmail',
  updateConferenceRegistrationLogStatistics = 'updateConferenceRegistrationLogStatistics',
  supportTaskEmailToAgent = 'supportTaskEmailToAgent',
  excursionsConfigurationsEmail = 'excursionsConfigurationsEmail',
  bulkEmailSending = 'bulkEmailSending',
  getAuthentication = 'getAuthentication',
  deleteAuthentication = 'deleteAuthentication',
  forceVerifyEmailAuthentication = 'forceVerifyEmailAuthentication',
  sendPushNotification = 'sendPushNotification',
  createCustomToken = 'createCustomToken',
  getStats = 'getStats',
  uploadFile = 'uploadFile',
  deleteWeekTransactions = 'deleteWeekTransactions',
  parseMSGFile = 'parseMSGFile',
  importTransactionsToElastic = 'importTransactionsToElastic',
  importTransactionsSummariesToElastic = 'importTransactionsSummariesToElastic',
  deleteConference = 'deleteConference',
}
