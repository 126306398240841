import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../../shared/pipes/pipes.module';
import { ChangesHistoryGridComponent } from './changes-history-grid.component';
import { DxDataGridModule } from 'devextreme-angular';
import { ChangesHistoryGridService } from './changes-history-grid.service';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogPipesModule } from './pipes/change-log-pipes.module';
import { ChangeLogDetailModule } from './components/change-log-detail/change-log-detail.module';
import { ChangeLogDetailByTypeModule } from './components/change-log-detail-by-type/change-log-detail-by-type.module';
import { PersonalInfoLogDetailsModule } from './components/personal-info-log-details/personal-info-log-details.module';
import { AttendeeRsvpLogDetailsModule } from './components/attendee-rsvp-log-details/attendee-rsvp-log-details.module';
import { AttendeeExcursionsLogDetailsModule } from './components/excursions-log-details/attendee-excursions-log-details.module';
import { AttendeeFlightInfoLogDetailsModule } from './components/attendee-flight-info-log-details/attendee-flight-info-log-details.module';
import { AttendeeGuestsLogDetailsModule } from './components/attendee-guests-log-details/attendee-guests-log-details.module';
import { AttendeeHotelReservationLogDetailsModule } from './components/attendee-hotel-reservation-log-details/attendee-hotel-reservation-log-details.module';
import { AgentInfoLogDetailsModule } from './components/agent-info-log-details/agent-info-log-details.module';

@NgModule({
  declarations: [ChangesHistoryGridComponent],
  exports: [ChangesHistoryGridComponent],
  imports: [
    CommonModule,
    PipesModule,
    DxDataGridModule,
    SharedModule,
    ChangeLogPipesModule,
    ChangeLogDetailModule,
    ChangeLogDetailByTypeModule,
    AttendeeRsvpLogDetailsModule,
    AttendeeExcursionsLogDetailsModule,
    AttendeeFlightInfoLogDetailsModule,
    AttendeeGuestsLogDetailsModule,
    AttendeeHotelReservationLogDetailsModule,
    PersonalInfoLogDetailsModule,
    AgentInfoLogDetailsModule,
  ],
  providers: [ChangesHistoryGridService],
})
export class ChangesHistoryGridModule {}
