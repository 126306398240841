import { ChangeLogsConfig, ChangeLogsConfigKey, ChangeLogsDataType } from '../../models/registration/change-logs.model';
import { Lookups } from '../../lists/lookups.enum';
import { WebsiteKeys } from '../../models/utils/website.model';
import { SocialKeys } from '../../models/utils/social.model';
import { BUSINESS_PERSONAL_TYPE_LOOKUP } from '../../lists/business-personal-type.list';
import { TRUE_FALSE_LOOKUP } from '../../lists/yes-no.list';
import { GoalKeys } from '../../models/utils/goal.model';
import { ApproveDenyReasonKeys } from '../..//models/utils/approve-deny-reason.model';
import { APPROVE_DENY_REASON_VISIBILITY_LEVEL_LOOKUP } from '../../lists/approve-deny-reason-visibility-level.list';
import { PHONE_NUMBER_KEYS_LOOKUP, PhoneNumberKeys } from '../../models/utils/phone-number.model';
import { PhoneNumberType } from '../../lists/phone_number_type.enum';
import { AddressModelKeys, AddressModelMap } from '../../models/utils/address.model';
import { AgentKeys, AGENTS_TITLE_LIST } from '../../models/domain/agent.model';
import { AGENT_TYPE_LOOKUP } from '../../lists/agent-type.enum';
import { ROLE_LOOKUP } from '../../lists/roles.enum';
import { AGENT_STATUSES_LOOKUP } from '../../lists/agent-status.list';
import { PROSPECT_STATUS_LOOKUP } from '../../lists/prospect-status.enum';
import { PROSPECT_PRIORITY_LOOKUP } from '../../lists/prospect-priority.enum';
import { PROSPECT_DISPOSITION_LOOKUP } from '../../lists/prospect-disposition.enum';
import { REGISTRATION_STATUS_LOOKUP } from '../../lists/registration-status.enum';
import { AGENT_REVIEW_LEVEL_LOOKUP } from '../../lists/agent-review-level.list';
import { createLookupWithCategory } from './change-logs-config.utils';
import { AgentCarrierModelKeys } from '../../models/utils/agent-carrier.model';
import { CARRIER_LINE_OF_BUSINESS_LOOKUP, CARRIER_ORGANIZATION_LOOKUP } from '../../lists/carrier.list';
import { StateLicenseKeys } from '../../models/utils/state-license.model';
import { FavoriteKeys } from '../../models/utils/favorite.model';
import { AgentCampaignsKeys } from '../../models/crm/agent-campaigns.model';
import { AGENT_CAMPAIGNS_STATUS_LOOKUP } from '../agent-campaigns-status.list';
import { sortLookupByDescription } from '../../../../../../../ag-common-svc/projects/ag-common-svc/src/shared/utils/sort-lookup-by-description';

const getWebsitesConfig = (): ChangeLogsConfig[] => {
  const websitesKeys = Object.values(WebsiteKeys).filter(key => ![WebsiteKeys.id].includes(key));
  const websitesConfig = [];
  websitesKeys.forEach(key => {
    const config = {
      value: key,
    };
    switch (key) {
      case WebsiteKeys.websiteType:
        config[ChangeLogsConfigKey.description] = 'Type';
        config[ChangeLogsConfigKey.activeLookupName] = Lookups.WebsiteTypes;
        break;

      case WebsiteKeys.url:
        config[ChangeLogsConfigKey.description] = 'URL';
        break;

      default:
        config[ChangeLogsConfigKey.description] = key;
        break;
    }
    websitesConfig.push(config);
  });

  return websitesConfig;
};

const getGoalsConfig = (): ChangeLogsConfig[] => {
  const goalsKeys = Object.values(GoalKeys).filter(key => key);
  const goalsConfig = [];
  goalsKeys.forEach(key => {
    const config = {
      value: key,
    };
    switch (key) {
      case GoalKeys.year:
        config[ChangeLogsConfigKey.description] = 'Year';
        break;

      case GoalKeys.amount:
        config[ChangeLogsConfigKey.description] = 'Amount';
        break;

      default:
        config[ChangeLogsConfigKey.description] = key;
        break;
    }
    goalsConfig.push(config);
  });

  return goalsConfig;
};

const getApproveDenyReasonConfig = (): ChangeLogsConfig[] => {
  const approveDenyReasonKeys = Object.values(ApproveDenyReasonKeys).filter(key => key);
  const approveDenyReasonConfig = [];
  approveDenyReasonKeys.forEach(key => {
    const config = {
      value: key,
    };
    switch (key) {
      case ApproveDenyReasonKeys.activity:
        config[ChangeLogsConfigKey.description] = 'Activity';
        break;

      case ApproveDenyReasonKeys.isSystem:
        config[ChangeLogsConfigKey.description] = 'Is System';
        break;

      case ApproveDenyReasonKeys.visibilityLevel:
        config[ChangeLogsConfigKey.description] = 'Visibility Level';
        config[ChangeLogsConfigKey.valueLookup] = APPROVE_DENY_REASON_VISIBILITY_LEVEL_LOOKUP;
        break;
    }
    approveDenyReasonConfig.push(config);
  });

  return approveDenyReasonConfig;
};

const getPhoneNumbersConfig = (): ChangeLogsConfig[] => {
  const phoneNumbersKeys = Object.values(PhoneNumberKeys).filter(key => key);
  const phoneNumbersConfig = [];
  phoneNumbersKeys.forEach(key => {
    const config = {
      value: key,
      description: PHONE_NUMBER_KEYS_LOOKUP.find(item => item.value === key)?.description,
    };
    switch (key) {
      case PhoneNumberKeys.phone_type:
        config[ChangeLogsConfigKey.valueLookup] = PhoneNumberType;
        break;

      case PhoneNumberKeys.supportType:
        config[ChangeLogsConfigKey.activeLookupName] = Lookups.SupportTypes;
        break;
    }
    phoneNumbersConfig.push(config);
  });

  return phoneNumbersConfig;
};

const getSocialsConfig = (): ChangeLogsConfig[] => {
  const socialsKeys = Object.values(SocialKeys).filter(key => ![SocialKeys.id].includes(key));
  const socialsConfig = [];
  socialsKeys.forEach(key => {
    const config = {
      value: key,
    };
    switch (key) {
      case SocialKeys.socialType:
        config[ChangeLogsConfigKey.description] = 'Type';
        config[ChangeLogsConfigKey.activeLookupName] = Lookups.SocialTypes;
        break;

      case SocialKeys.url:
        config[ChangeLogsConfigKey.description] = 'URL';
        break;

      case SocialKeys.socialMedia:
        config[ChangeLogsConfigKey.description] = 'Social Media';
        config[ChangeLogsConfigKey.activeLookupName] = Lookups.SocialMedia;
        break;

      default:
        config[ChangeLogsConfigKey.description] = key;
        break;
    }
    socialsConfig.push(config);
  });

  return socialsConfig;
};

const getAgentCarrierConfig = (): ChangeLogsConfig[] => {
  const agentCarrierKeys = Object.values(AgentCarrierModelKeys).filter(
    key => ![AgentCarrierModelKeys.id].includes(key),
  );
  const agentCarrierConfig = [];
  agentCarrierKeys.forEach(key => {
    const config = {
      value: key,
    };
    switch (key) {
      case AgentCarrierModelKeys.carrier:
        config[ChangeLogsConfigKey.description] = 'Carrier';
        break;

      case AgentCarrierModelKeys.name:
        config[ChangeLogsConfigKey.description] = 'Name';
        break;

      case AgentCarrierModelKeys.emailAddress:
        config[ChangeLogsConfigKey.description] = 'Email Address';
        break;

      case AgentCarrierModelKeys.writingNumber:
        config[ChangeLogsConfigKey.description] = 'Writing Number';
        break;

      case AgentCarrierModelKeys.isActive:
        config[ChangeLogsConfigKey.description] = 'Active';
        config[ChangeLogsConfigKey.valueLookup] = TRUE_FALSE_LOOKUP;
        break;

      case AgentCarrierModelKeys.individualOrCorp:
        config[ChangeLogsConfigKey.description] = 'Individual / Corp';
        config[ChangeLogsConfigKey.valueLookup] = CARRIER_ORGANIZATION_LOOKUP;
        break;

      case AgentCarrierModelKeys.lineOfBusiness:
        config[ChangeLogsConfigKey.description] = 'Line Of Business';
        config[ChangeLogsConfigKey.valueLookup] = CARRIER_LINE_OF_BUSINESS_LOOKUP;
        break;

      default:
        config[ChangeLogsConfigKey.description] = key;
        break;
    }
    agentCarrierConfig.push(config);
  });

  return agentCarrierConfig;
};

const getFavoritesConfig = (): ChangeLogsConfig[] => {
  const favoritesKeys = Object.values(FavoriteKeys);
  const favoritesConfig = [];
  favoritesKeys.forEach(key => {
    const config = {
      value: key,
    };
    switch (key) {
      case FavoriteKeys.pageId:
        config[ChangeLogsConfigKey.description] = 'Page ID';
        break;

      case FavoriteKeys.pageName:
        config[ChangeLogsConfigKey.description] = 'Page Name';
        break;

      case FavoriteKeys.pageRoute:
        config[ChangeLogsConfigKey.description] = 'Page Route';
        break;

      default:
        config[ChangeLogsConfigKey.description] = key;
        break;
    }
    favoritesConfig.push(config);
  });

  return favoritesConfig;
};

const getCampaignesConfig = (): ChangeLogsConfig[] => {
  const campaignesKeys = Object.values(AgentCampaignsKeys);
  const campaignesConfig = [];
  campaignesKeys.forEach(key => {
    const config = {
      value: key,
    };
    switch (key) {
      case AgentCampaignsKeys.originalOutstandUser:
        config[ChangeLogsConfigKey.description] = 'Original Outstand User';
        config[ChangeLogsConfigKey.valueLookup] = TRUE_FALSE_LOOKUP;
        break;

      case AgentCampaignsKeys.outstandContacts:
        config[ChangeLogsConfigKey.description] = 'Outstand Contacts';
        break;

      case AgentCampaignsKeys.outstandEmailsSent:
        config[ChangeLogsConfigKey.description] = 'Outstand Emails Sent';
        break;

      case AgentCampaignsKeys.outstandSite:
        config[ChangeLogsConfigKey.description] = 'Outstand Site';
        break;

      case AgentCampaignsKeys.outstandSubscriptionLevel:
        config[ChangeLogsConfigKey.description] = 'Outstand Subscription Level';
        break;

      case AgentCampaignsKeys.campaignsStatus:
        config[ChangeLogsConfigKey.description] = 'Campaigns Status';
        config[ChangeLogsConfigKey.valueLookup] = AGENT_CAMPAIGNS_STATUS_LOOKUP;
        break;

      case AgentCampaignsKeys.userSince:
        config[ChangeLogsConfigKey.description] = 'User Since';
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.timeStamp;
        break;

      case AgentCampaignsKeys.stoppedCampaigns:
        config[ChangeLogsConfigKey.description] = 'Stopped Campaigns';
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.timeStamp;
        break;

      default:
        config[ChangeLogsConfigKey.description] = key;
        break;
    }
    campaignesConfig.push(config);
  });

  return campaignesConfig;
};

const getAgentStateLicensesConfig = (): ChangeLogsConfig[] => {
  const agentStateLicensesKeys = Object.values(StateLicenseKeys);
  const agentStateLicensesConfig = [];
  agentStateLicensesKeys.forEach(key => {
    const config = {
      value: key,
    };

    switch (key) {
      case StateLicenseKeys.state:
        config[ChangeLogsConfigKey.description] = 'State';
        break;

      case StateLicenseKeys.licenseNumber:
        config[ChangeLogsConfigKey.description] = 'License Number';
        break;

      case StateLicenseKeys.residency:
        config[ChangeLogsConfigKey.description] = 'Residency';
        break;

      case StateLicenseKeys.expiration:
        config[ChangeLogsConfigKey.description] = 'Expiration';
        config[ChangeLogsConfigKey.dataType] = ChangeLogsDataType.timeStamp;
        break;

      default:
        config[ChangeLogsConfigKey.description] = key;
        break;
    }
    agentStateLicensesConfig.push(config);
  });

  return agentStateLicensesConfig;
};

const getAddressesConfig = (): ChangeLogsConfig[] => {
  const addressesKeys = [
    AddressModelKeys.address1,
    AddressModelKeys.address2,
    AddressModelKeys.city,
    AddressModelKeys.state,
    AddressModelKeys.zip,
    AddressModelKeys.country,
    AddressModelKeys.county,
    AddressModelKeys.addressType,
    AddressModelKeys.isPrimaryShipping,
    AddressModelKeys.isPrimaryBilling,
    AddressModelKeys.isPhysicalLocation,
  ];
  const addressesConfig = [];
  addressesKeys.forEach(key => {
    const config = {
      value: key,
      description: AddressModelMap[key],
    };
    switch (key) {
      case AddressModelKeys.addressType:
        config[ChangeLogsConfigKey.valueLookup] = BUSINESS_PERSONAL_TYPE_LOOKUP;
        break;

      case AddressModelKeys.isPrimaryShipping:
      case AddressModelKeys.isPrimaryBilling:
      case AddressModelKeys.isPhysicalLocation:
        config[ChangeLogsConfigKey.valueLookup] = TRUE_FALSE_LOOKUP;
        break;
    }
    addressesConfig.push(config);
  });

  return addressesConfig;
};

// AGENT TYPE
export const WEBSITES_KEYS_CONFIG: ChangeLogsConfig[] = getWebsitesConfig();
export const SOCIALS_KEYS_CONFIG: ChangeLogsConfig[] = getSocialsConfig();
export const ADDRESSES_KEYS_CONFIG: ChangeLogsConfig[] = getAddressesConfig();
export const GOALS_KEYS_CONFIG: ChangeLogsConfig[] = getGoalsConfig();
export const PHONE_NUMBERS_KEYS_CONFIG: ChangeLogsConfig[] = getPhoneNumbersConfig();
export const APPROVE_DENY_REASON_KEYS_CONFIG: ChangeLogsConfig[] = getApproveDenyReasonConfig();
export const AGENT_CARRIER_KEYS_CONFIG: ChangeLogsConfig[] = getAgentCarrierConfig();
export const AGENT_STATE_LICENSES_KEYS_CONFIG: ChangeLogsConfig[] = getAgentStateLicensesConfig();
export const FAVORITES_KEYS_CONFIG: ChangeLogsConfig[] = getFavoritesConfig();
export const CAMPAIGNS_KEYS_CONFIG: ChangeLogsConfig[] = getCampaignesConfig();
export const AGENT_OTHER_KEYS_CONFIG: ChangeLogsConfig[] = [
  {
    value: AgentKeys.uid,
    description: 'UID',
  },
  {
    value: AgentKeys.p_email,
    description: AGENTS_TITLE_LIST[AgentKeys.p_email],
  },
  {
    value: AgentKeys.p_agent_name,
    description: AGENTS_TITLE_LIST[AgentKeys.p_agent_name],
  },
  {
    value: AgentKeys.p_agent_first_name,
    description: AGENTS_TITLE_LIST[AgentKeys.p_agent_first_name],
  },
  {
    value: AgentKeys.p_agent_middle_name,
    description: AGENTS_TITLE_LIST[AgentKeys.p_agent_middle_name],
  },
  {
    value: AgentKeys.p_agent_last_name,
    description: AGENTS_TITLE_LIST[AgentKeys.p_agent_last_name],
  },
  {
    value: AgentKeys.p_passport_first_name,
    description: 'Passport First Name',
  },
  {
    value: AgentKeys.p_passport_last_name,
    description: 'Passport Last Name',
  },
  {
    value: AgentKeys.p_suffix,
    description: AGENTS_TITLE_LIST[AgentKeys.p_suffix],
    activeLookupName: Lookups.Suffixes,
  },
  {
    value: AgentKeys.p_prefix,
    description: AGENTS_TITLE_LIST[AgentKeys.p_prefix],
    activeLookupName: Lookups.Prefixes,
  },
  {
    value: AgentKeys.showSplashScreen,
    description: AGENTS_TITLE_LIST[AgentKeys.showSplashScreen],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.emailVerified,
    description: AGENTS_TITLE_LIST[AgentKeys.emailVerified],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.registrationDate,
    description: AGENTS_TITLE_LIST[AgentKeys.registrationDate],
    dataType: ChangeLogsDataType.localDateTimeString,
  },
  {
    value: AgentKeys.approvalDate,
    description: AGENTS_TITLE_LIST[AgentKeys.approvalDate],
    dataType: ChangeLogsDataType.localDateTimeString,
  },
  {
    value: AgentKeys.approvedBy,
    description: AGENTS_TITLE_LIST[AgentKeys.approvedBy],
  },
  {
    value: AgentKeys.billing_shipping_same,
    description: AGENTS_TITLE_LIST[AgentKeys.billing_shipping_same],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.agencyName,
    description: AGENTS_TITLE_LIST[AgentKeys.agencyName],
  },
  {
    value: AgentKeys.registration_source,
    description: AGENTS_TITLE_LIST[AgentKeys.registration_source],
  },
  {
    value: AgentKeys.prospect_referred_to,
    description: AGENTS_TITLE_LIST[AgentKeys.prospect_referred_to],
  },
  {
    value: AgentKeys.prospect_wrap_up_notes,
    description: AGENTS_TITLE_LIST[AgentKeys.prospect_wrap_up_notes],
  },
  {
    value: AgentKeys.is_acb_user,
    description: AGENTS_TITLE_LIST[AgentKeys.is_acb_user],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.is_awb_user,
    description: AGENTS_TITLE_LIST[AgentKeys.is_awb_user],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.is_arm_user,
    description: AGENTS_TITLE_LIST[AgentKeys.is_arm_user],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.awb_site_id,
    description: AGENTS_TITLE_LIST[AgentKeys.awb_site_id],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.awb_site_id,
    description: AGENTS_TITLE_LIST[AgentKeys.awb_site_id],
  },
  {
    value: AgentKeys.certifications,
    description: AGENTS_TITLE_LIST[AgentKeys.certifications],
  },
  {
    value: AgentKeys.prospect_priority,
    description: AGENTS_TITLE_LIST[AgentKeys.prospect_priority],
    valueLookup: PROSPECT_PRIORITY_LOOKUP,
  },
  {
    value: AgentKeys.prospect_disposition,
    description: AGENTS_TITLE_LIST[AgentKeys.prospect_disposition],
    valueLookup: PROSPECT_DISPOSITION_LOOKUP,
  },
  {
    value: AgentKeys.registration_status,
    description: AGENTS_TITLE_LIST[AgentKeys.registration_status],
    valueLookup: REGISTRATION_STATUS_LOOKUP,
  },
  {
    value: AgentKeys.prospect_referred_to_date,
    description: AGENTS_TITLE_LIST[AgentKeys.prospect_referred_to_date],
    dataType: ChangeLogsDataType.localDateTimeString,
  },
  {
    value: AgentKeys.headshot,
    description: AGENTS_TITLE_LIST[AgentKeys.headshot],
    dataType: ChangeLogsDataType.headshot,
  },
  // todo: create components to show data
  {
    value: AgentKeys.personal_goals,
    description: AGENTS_TITLE_LIST[AgentKeys.personal_goals],
  },
  {
    value: AgentKeys.conference_goals,
    description: AGENTS_TITLE_LIST[AgentKeys.conference_goals],
  },
  {
    value: AgentKeys.manager_goals,
    description: AGENTS_TITLE_LIST[AgentKeys.manager_goals],
  },
  {
    value: AgentKeys.favorites,
    description: AGENTS_TITLE_LIST[AgentKeys.favorites],
  },
  {
    value: AgentKeys.notificationChannels,
    description: AGENTS_TITLE_LIST[AgentKeys.notificationChannels],
  },
];

export const AGENT_CONTACT_INFO_SEARCH_KEYS_CONFIG: ChangeLogsConfig[] = [
  {
    value: AgentKeys.addresses,
    description: AGENTS_TITLE_LIST[AgentKeys.addresses],
    dataType: ChangeLogsDataType.address,
  },
  {
    value: AgentKeys.phone_numbers,
    description: AGENTS_TITLE_LIST[AgentKeys.phone_numbers],
    dataType: ChangeLogsDataType.phoneNumber,
  },
  {
    value: AgentKeys.socials,
    description: AGENTS_TITLE_LIST[AgentKeys.socials],
  },
  {
    value: AgentKeys.websites,
    description: AGENTS_TITLE_LIST[AgentKeys.websites],
  },
  {
    value: AgentKeys.email_addresses,
    description: AGENTS_TITLE_LIST[AgentKeys.email_addresses],
    dataType: ChangeLogsDataType.emailAddress,
  },
];

export const AGENT_PERSONAL_INFO_SEARCH_KEYS_CONFIG: ChangeLogsConfig[] = [
  {
    value: AgentKeys.dob,
    description: AGENTS_TITLE_LIST[AgentKeys.dob],
    dataType: ChangeLogsDataType.localDateTimeString,
  },
  {
    value: AgentKeys.race,
    description: AGENTS_TITLE_LIST[AgentKeys.race],
  },
  {
    value: AgentKeys.ethnicity,
    description: AGENTS_TITLE_LIST[AgentKeys.ethnicity],
  },
  {
    value: AgentKeys.gender,
    description: AGENTS_TITLE_LIST[AgentKeys.gender],
    activeLookupName: Lookups.Genders,
  },
  {
    value: AgentKeys.hobbies,
    description: AGENTS_TITLE_LIST[AgentKeys.hobbies],
  },
  {
    value: AgentKeys.primary_language,
    description: AGENTS_TITLE_LIST[AgentKeys.primary_language],
  },
  {
    value: AgentKeys.secondary_language,
    description: AGENTS_TITLE_LIST[AgentKeys.secondary_language],
  },
  {
    value: AgentKeys.favorite_destination,
    description: AGENTS_TITLE_LIST[AgentKeys.favorite_destination],
  },
  {
    value: AgentKeys.shoe_size,
    description: AGENTS_TITLE_LIST[AgentKeys.shoe_size],
  },
  {
    value: AgentKeys.tShirtSizes,
    dataType: ChangeLogsDataType.tShirtSizes,
    description: 'T-Shirt Size',
  },
  {
    value: AgentKeys.dietaryConsideration,
    description: AGENTS_TITLE_LIST[AgentKeys.dietaryConsideration],
    dataType: ChangeLogsDataType.dietaryConsideration,
  },
  // todo: add assotiation
];

export const AGENT_INFO_SEARCH_KEYS_CONFIG: ChangeLogsConfig[] = [
  {
    value: AgentKeys.p_external_agent_id,
    description: 'External Agent ID',
  },
  {
    value: AgentKeys.p_agent_id,
    description: AGENTS_TITLE_LIST[AgentKeys.p_agent_id],
  },
  {
    value: AgentKeys.role,
    description: AGENTS_TITLE_LIST[AgentKeys.role],
    valueLookup: ROLE_LOOKUP,
  },
  {
    value: AgentKeys.roles,
    description: AGENTS_TITLE_LIST[AgentKeys.roles],
  },
  {
    value: AgentKeys.agent_status,
    description: AGENTS_TITLE_LIST[AgentKeys.agent_status],
    valueLookup: AGENT_STATUSES_LOOKUP,
  },
  {
    value: AgentKeys.prospect_status,
    description: AGENTS_TITLE_LIST[AgentKeys.prospect_status],
    valueLookup: PROSPECT_STATUS_LOOKUP,
  },
  {
    value: AgentKeys.prospect_status_update_date,
    description: AGENTS_TITLE_LIST[AgentKeys.prospect_status_update_date],
    dataType: ChangeLogsDataType.timeStamp,
  },
  {
    value: AgentKeys.p_strategic_agent,
    description: AGENTS_TITLE_LIST[AgentKeys.p_strategic_agent],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.alliance_group_employee,
    description: AGENTS_TITLE_LIST[AgentKeys.alliance_group_employee],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.agent_type,
    description: AGENTS_TITLE_LIST[AgentKeys.agent_type],
    valueLookup: AGENT_TYPE_LOOKUP,
  },
  {
    value: AgentKeys.p_registered_user,
    description: AGENTS_TITLE_LIST[AgentKeys.p_registered_user],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.agent_review_level,
    description: AGENTS_TITLE_LIST[AgentKeys.agent_review_level],
    valueLookup: AGENT_REVIEW_LEVEL_LOOKUP,
  },
  {
    value: AgentKeys.is_rmd,
    description: AGENTS_TITLE_LIST[AgentKeys.is_rmd],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.christmas_card,
    description: AGENTS_TITLE_LIST[AgentKeys.christmas_card],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.conference_poster,
    description: AGENTS_TITLE_LIST[AgentKeys.conference_poster],
    dataType: ChangeLogsDataType.boolean,
  },
  // MGA
  {
    value: AgentKeys.p_agency_id,
    description: AGENTS_TITLE_LIST[AgentKeys.p_agency_id],
  },
  {
    value: AgentKeys.p_mga_id,
    description: AGENTS_TITLE_LIST[AgentKeys.p_mga_id],
  },
  {
    value: AgentKeys.upline,
    description: AGENTS_TITLE_LIST[AgentKeys.upline],
  },
  {
    value: AgentKeys.is_manager,
    description: AGENTS_TITLE_LIST[AgentKeys.is_manager],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.is_credited,
    description: AGENTS_TITLE_LIST[AgentKeys.is_credited],
    dataType: ChangeLogsDataType.boolean,
  },
  {
    value: AgentKeys.manager_id,
    description: AGENTS_TITLE_LIST[AgentKeys.manager_id],
  },
  // Agent Carrier
  {
    value: AgentKeys.agent_carriers,
    description: AGENTS_TITLE_LIST[AgentKeys.agent_carriers],
  },
  // Department Of Insurance Information
  {
    value: AgentKeys.npn,
    description: AGENTS_TITLE_LIST[AgentKeys.npn],
  },
  // States Licensed In
  {
    value: AgentKeys.state_licenses,
    description: AGENTS_TITLE_LIST[AgentKeys.state_licenses],
  },
  // Campaigns
  {
    value: AgentKeys.campaigns,
    description: AGENTS_TITLE_LIST[AgentKeys.campaigns],
  },
  {
    value: AgentKeys.title,
    description: AGENTS_TITLE_LIST[AgentKeys.title],
  },
  {
    value: AgentKeys.isActiveCampaigns,
    description: AGENTS_TITLE_LIST[AgentKeys.isActiveCampaigns],
    dataType: ChangeLogsDataType.boolean,
  },
  // todo: Agent Status Activity
  {
    value: AgentKeys.approve_deny_reason,
    description: AGENTS_TITLE_LIST[AgentKeys.approve_deny_reason],
    dataType: ChangeLogsDataType.approveDenyReason,
  },
  {
    value: AgentKeys.agency_approve_deny_reason,
    description: AGENTS_TITLE_LIST[AgentKeys.agency_approve_deny_reason],
    dataType: ChangeLogsDataType.approveDenyReason,
  },
  // todo: Need To Know
];

export const AGENT_SEARCH_KEYS_CONFIG: ChangeLogsConfig[] = [
  ...AGENT_OTHER_KEYS_CONFIG,
  ...AGENT_INFO_SEARCH_KEYS_CONFIG,
  ...AGENT_PERSONAL_INFO_SEARCH_KEYS_CONFIG,
  ...AGENT_CONTACT_INFO_SEARCH_KEYS_CONFIG,
];

export const excludedChangeLogsFields = [
  'actionId',
  'last_login_date',
  'login_count',
  'updated_date',
  'updated_by',
  'updated_by_agent_dbId',
  'updated_by_email',
  'created_by',
  'created_by_agent_dbId',
  'created_by_email',
  'created_date',
  'dbId',
  'actionName',
  'changedBySource',
];

export const agentContactInfoLookup = createLookupWithCategory(sortLookupByDescription(AGENT_CONTACT_INFO_SEARCH_KEYS_CONFIG), 'Contact Info');
export const agentPersonalInfoLookup = createLookupWithCategory(
    sortLookupByDescription(AGENT_PERSONAL_INFO_SEARCH_KEYS_CONFIG),
  'Personal Info',
);
export const agentInfoLookup = createLookupWithCategory(sortLookupByDescription(AGENT_INFO_SEARCH_KEYS_CONFIG), 'Agent Info');
export const agentOtherLookup = createLookupWithCategory(sortLookupByDescription(AGENT_OTHER_KEYS_CONFIG), 'Other');
export const agentChangeLogsLookup = [
  ...agentInfoLookup,
  ...agentContactInfoLookup,
  ...agentPersonalInfoLookup,
  ...agentOtherLookup,
];
