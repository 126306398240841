<ng-container *ngIf="!!logs; else emptyLog">
  <ng-container *ngFor="let ind of logIndexes">
    <ng-container *ngIf="logs[ind] as log">
      <h6 class="fw-bold">{{ caption | changeLogCaptionByIndex: ind }}</h6>

      <ng-container [ngSwitch]="datType">
        <div *ngSwitchCase="ChangeLogsDataType.address">
          <ng-container *ngIf="ind.toString() === '0'; else addressDetailsTemp">
            <ng-container
              *ngTemplateOutlet="
                defaultChangeLogTemp;
                context: {
                  keysConfig: keysConfig,
                  log: log,
                }
              "
            ></ng-container>
          </ng-container>

          <ng-template #addressDetailsTemp>
            <ag-shr-change-log-address-detail
              [ngClass]="{ 'change-log-detail--removed-item': ind.toString() === '_1' }"
              [log]="log[0]"
            ></ag-shr-change-log-address-detail>
          </ng-template>
        </div>

        <div *ngSwitchCase="ChangeLogsDataType.phoneNumber">
          <ng-container *ngIf="ind.toString() === '0'; else phoneDetailsTemp">
            <ng-container
              *ngTemplateOutlet="
                defaultChangeLogTemp;
                context: {
                  keysConfig: keysConfig,
                  log: log,
                }
              "
            ></ng-container>
          </ng-container>

          <ng-template #phoneDetailsTemp>
            <ag-shr-change-log-phone-detail
              [ngClass]="{ 'change-log-detail--removed-item': ind.toString() === '_1' }"
              [log]="log[0]"
            ></ag-shr-change-log-phone-detail>
          </ng-template>
        </div>

        <div *ngSwitchDefault>
          <ng-container
            *ngTemplateOutlet="
              defaultChangeLogTemp;
              context: {
                keysConfig: keysConfig,
                log: log,
              }
            "
          ></ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #defaultChangeLogTemp let-log="log" let-keysConfig="keysConfig">
  <ag-shr-change-log-detail-by-type
    [keysConfig]="keysConfig"
    [changeLogs]="Array.isArray(log) ? log[0] : log"
    [showRemovedData]="false"
  ></ag-shr-change-log-detail-by-type>
</ng-template>

<ng-template #emptyLog><span>&mdash;</span></ng-template>
