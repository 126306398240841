import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogDetailModule } from '../../change-log-detail/change-log-detail.module';
import { ChangeLogFlightBookingInfoComponent } from './change-log-flight-booking-info.component';
import { ChangeLogPipesModule } from '../../../pipes/change-log-pipes.module';
import { ChangeLogDetailByTypeModule } from '../../change-log-detail-by-type/change-log-detail-by-type.module';

@NgModule({
  declarations: [ChangeLogFlightBookingInfoComponent],
  imports: [CommonModule, SharedModule, ChangeLogDetailModule, ChangeLogPipesModule, ChangeLogDetailByTypeModule],
  exports: [ChangeLogFlightBookingInfoComponent],
})
export class ChangeLogFlightBookingInfoModule {}
