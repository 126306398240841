import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { getLogIndexes, hasLog, normalizeLog } from '../../../utils/normalize-log';
import { ChangeLogsConfig, ChangeLogsDataType } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-agent-change-log-details-by-type',
  templateUrl: './agent-change-log-details-by-type.component.html',
  styleUrls: ['./agent-change-log-details-by-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentChangeLogDetailsByTypeComponent {
  @Input() keysConfig: ChangeLogsConfig[];
  @Input() caption = 'Item';
  @Input() datType: ChangeLogsDataType;
  @Input()
  set logs(delta: Delta) {
    // Delta for changed object is object of objects
    if (!Array.isArray(delta)) {
      this._logs = delta;
      this.logIndexes = getLogIndexes(Object.entries(this._logs));
      return;
    }
    // Delta for new value is array with array of objects
    this._logs = delta[0] as Delta;
    this.logIndexes = getLogIndexes(this._logs);
  }
  get logs(): Delta {
    return this._logs;
  }
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly Array = Array;
  private _logs: Delta;
  logIndexes: number[] = [];
  protected readonly ChangeLogsDataType = ChangeLogsDataType;
}
