import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogPipesModule } from '../../../pipes/change-log-pipes.module';
import { ChangeLogDetailByTypeModule } from '../../change-log-detail-by-type/change-log-detail-by-type.module';
import { AgentChangeLogDetailsByTypeComponent } from './agent-change-log-details-by-type.component';
import { ChangeLogAddressModule } from '../../change-log-detail/address/change-log-address.module';
import { ChangeLogPhoneModule } from '../../change-log-detail/phone-number/change-log-phone.module';

@NgModule({
  declarations: [AgentChangeLogDetailsByTypeComponent],
  imports: [
    CommonModule,
    SharedModule,
    ChangeLogPipesModule,
    ChangeLogDetailByTypeModule,
    ChangeLogAddressModule,
    ChangeLogPhoneModule,
  ],
  exports: [AgentChangeLogDetailsByTypeComponent],
})
export class AgentChangeLogDetailsByTypeModule {}
