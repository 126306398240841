import { Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { SELECTED_EXCURSION_CONFIGURATIONS_CONFIG } from '@ag-common-lib/public-api';
import { getConfigurationName } from 'ag-common-svc/lib/utils/excursions-data.utils';
import { logHasKey } from '../../../utils/normalize-log';

@Component({
  selector: 'ag-shr-change-log-excursion-configurations',
  templateUrl: './change-log-excursion-configurations.component.html',
  styleUrls: ['./change-log-excursion-configurations.component.scss'],
})
export class ChangeLogExcursionConfigurationsComponent {
  @Input() set log(delta: Delta) {
    this._log = !Array.isArray(delta) ? delta : (delta[0] as Delta);
    this.getExcursionsConfigurationsLogIndexes(this._log);
  }
  get log(): Delta {
    return this._log;
  }

  configurationsLogIndexes: string[] = [];

  protected readonly configurationsLookup = SELECTED_EXCURSION_CONFIGURATIONS_CONFIG;
  protected readonly logHasKey = logHasKey;
  private _log: Delta;

  private getExcursionsConfigurationsLogIndexes = logs => {
    this.configurationsLogIndexes = [];
    Object.keys(logs).forEach(key => this.configurationsLogIndexes.push(key));
  };

  getConfigurationFullName = configurationName => getConfigurationName(configurationName);
}
