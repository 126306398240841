<p class="change-log-detail__item">
  <u>{{ item }}</u>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="ChangeLogsType.changed">
      value changed
      <span *ngIf="fromValue || type === ChangeLogsType.changed"
        >from
        <span class="change-log-detail--old-value">
          <ng-container
            *ngTemplateOutlet="
              detailsContainer;
              context: {
                value: fromValue,
                valueLookup: valueLookup,
                activeLookupName: activeLookupName,
                dataType: dataType,
              }
            "
          ></ng-container></span
      ></span>
      <span *ngIf="toValue || type === ChangeLogsType.changed">
        to
        <span class="change-log-detail--new-value">
          <ng-container
            *ngTemplateOutlet="
              detailsContainer;
              context: {
                value: toValue,
                valueLookup: valueLookup,
                activeLookupName: activeLookupName,
                dataType: dataType,
              }
            "
          ></ng-container></span
      ></span>
    </ng-container>

    <ng-container *ngSwitchCase="ChangeLogsType.removed">
      value was
      <span class="change-log-detail--old-value">
        <ng-container
          *ngTemplateOutlet="
            detailsContainer;
            context: {
              value: toValue,
              valueLookup: valueLookup,
              activeLookupName: activeLookupName,
              dataType: dataType,
            }
          "
        ></ng-container
      ></span>
    </ng-container>

    <ng-container *ngSwitchCase="ChangeLogsType.added">
      value added as
      <span class="change-log-detail--new-value">
        <ng-container
          *ngTemplateOutlet="
            detailsContainer;
            context: {
              value: toValue,
              valueLookup: valueLookup,
              activeLookupName: activeLookupName,
              dataType: dataType,
            }
          "
        ></ng-container
      ></span>
    </ng-container>

    <ng-container *ngSwitchCase="ChangeLogsType.object">
      value set as
      <span class="change-log-detail--new-value">
        <ng-container
          *ngTemplateOutlet="
            detailsContainer;
            context: {
              value: toValue,
              valueLookup: valueLookup,
              activeLookupName: activeLookupName,
              dataType: dataType,
            }
          "
        ></ng-container>
      </span>
    </ng-container>
  </ng-container>
</p>

<ng-template
  #detailsContainer
  let-value="value"
  let-dataType="dataType"
  let-valueLookup="valueLookup"
  let-activeLookupName="activeLookupName"
>
  <ng-container *ngIf="valueLookup; else activeLookupTmp">
    {{ value | lookupDescriptionText: valueLookup | empty }}
  </ng-container>

  <ng-template #activeLookupTmp>
    <ng-container *ngIf="activeLookupName; else dataTypeTmp">{{
      value | lookupDescription: activeLookupName | async | empty
    }}</ng-container>
  </ng-template>

  <ng-template #dataTypeTmp>
    <ng-container *ngIf="dataType; else noLookupTmp">
      <ng-container [ngSwitch]="dataType">
        <span *ngSwitchCase="ChangeLogsDataType.localDateTimeString">
          {{ value | date: dateFormat | empty }}
        </span>

        <span *ngSwitchCase="ChangeLogsDataType.timeStamp">
          {{ value | changeLogTimeStampToLocalDate | date: dateFormat | empty }}
        </span>

        <span *ngSwitchCase="ChangeLogsDataType.currency">{{ value | currency: "USD" : "symbol" }}</span>

        <span *ngSwitchCase="ChangeLogsDataType.boolean">{{ getBooleanLookupDescription(value) }}</span>

        <div class="pl-4 ps-4" *ngSwitchCase="ChangeLogsDataType.emailAddress">
          <ag-shr-change-log-email-detail [log]="value"></ag-shr-change-log-email-detail>
        </div>

        <div class="pl-4 ps-4" *ngSwitchCase="ChangeLogsDataType.phoneNumber">
          <ag-shr-change-log-phone-detail [log]="value"></ag-shr-change-log-phone-detail>
        </div>

        <div class="pl-4 ps-4" *ngSwitchCase="ChangeLogsDataType.address">
          <ag-shr-change-log-address-detail [log]="value"></ag-shr-change-log-address-detail>
        </div>

        <div class="pl-4 ps-4" *ngSwitchCase="ChangeLogsDataType.tShirtSizes">
          <ag-shr-change-log-tshirt-size-detail [log]="value"></ag-shr-change-log-tshirt-size-detail>
        </div>

        <div class="pl-4 ps-4" *ngSwitchCase="ChangeLogsDataType.dietaryConsideration">
          <ag-shr-change-log-dietary-consideration-detail
            [log]="value"
          ></ag-shr-change-log-dietary-consideration-detail>
        </div>

        <div class="pl-4 ps-4" *ngSwitchCase="ChangeLogsDataType.association">
          <ag-shr-change-log-association-detail [log]="value"></ag-shr-change-log-association-detail>
        </div>

        <div class="pl-4 ps-4" *ngSwitchCase="ChangeLogsDataType.headshot">
          <ag-shr-change-log-headshot-detail [log]="value"></ag-shr-change-log-headshot-detail>
        </div>

        <div class="pl-4 ps-4" *ngSwitchCase="ChangeLogsDataType.requestOutcome">
          <ag-shr-change-log-request-outcome-detail [log]="value"></ag-shr-change-log-request-outcome-detail>
        </div>

        <span *ngSwitchDefault>{{ value }}</span>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #noLookupTmp>{{ value | empty }}</ng-template>
</ng-template>
