import { NgModule } from '@angular/core';
import { ChangeLogExcursionConfigurationsComponent } from './change-log-excursion-configurations.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogDetailModule } from '../../change-log-detail/change-log-detail.module';
import { ChangeLogPipesModule } from '../../../pipes/change-log-pipes.module';

@NgModule({
  declarations: [ChangeLogExcursionConfigurationsComponent],
  imports: [CommonModule, SharedModule, ChangeLogDetailModule, ChangeLogPipesModule],
  exports: [ChangeLogExcursionConfigurationsComponent],
})
export class ChangeLogExcursionConfigurationsModule {}
