import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogDetailModule } from '../change-log-detail/change-log-detail.module';
import { AttendeeHotelReservationLogDetailsComponent } from './attendee-hotel-reservation-log-details.component';
import { ChangeLogBookingInfoModule } from './booking-info/change-log-booking-info.module';
import { ChangeLogRequestOutcomeModule } from '../change-log-detail/request-outcome/change-log-request-outcome.module';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';
import { ChangeLogDetailByTypeModule } from '../change-log-detail-by-type/change-log-detail-by-type.module';

@NgModule({
  declarations: [AttendeeHotelReservationLogDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    ChangeLogDetailModule,
    ChangeLogBookingInfoModule,
    ChangeLogRequestOutcomeModule,
    ChangeLogPipesModule,
    ChangeLogDetailByTypeModule,
  ],
  exports: [AttendeeHotelReservationLogDetailsComponent],
})
export class AttendeeHotelReservationLogDetailsModule {}
