export class Report {
  dbId?: string;
  fileName: string;
  uploadDate: string;
  uploadedBy: string;
  year: number;
  recordsImported: number;
  summariesGenerated: number;
  asOfDate: string;
  sourceFileKey: string;
}
