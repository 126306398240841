import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogDetailModule } from '../change-log-detail/change-log-detail.module';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';
import { AttendeeRsvpLogDetailsComponent } from './attendee-rsvp-log-details.component';
import { ChangeLogDetailByTypeModule } from '../change-log-detail-by-type/change-log-detail-by-type.module';

@NgModule({
  declarations: [AttendeeRsvpLogDetailsComponent],
  imports: [CommonModule, SharedModule, ChangeLogDetailModule, ChangeLogPipesModule, ChangeLogDetailByTypeModule],
  exports: [AttendeeRsvpLogDetailsComponent],
})
export class AttendeeRsvpLogDetailsModule {}
