import { BaseModel } from '../base.model';
import { MappingProperty } from '../functions';
import { PolicyTransactionKeys } from './policy-transaction.model';

export class PolicyTransactionSummary extends BaseModel {
  [PolicyTransactionKeys.mgaId]?: string;
  [PolicyTransactionKeys.agentId]?: string;
  [PolicyTransactionKeys.agentName]?: string;
  [PolicyTransactionKeys.faceAmount]?: number = 0;
  [PolicyTransactionKeys.lifePrem]?: number = 0;
  [PolicyTransactionKeys.targetPrem]?: number = 0;
  [PolicyTransactionKeys.excessPrem]?: number = 0;
  [PolicyTransactionKeys.annuity]?: number = 0;
  [PolicyTransactionKeys.policies]?: number = 0;
  [PolicyTransactionKeys.weightedPrem]?: number = 0;
  [PolicyTransactionKeys.year]?: number;
}

export const PolicyTransactionSummaryMapping: {
  [key: string]: MappingProperty;
} = {
  [PolicyTransactionKeys.mgaId]: { type: 'keyword' },
  [PolicyTransactionKeys.agentId]: { type: 'keyword' },
  [PolicyTransactionKeys.agentName]: { type: 'keyword' },
  [PolicyTransactionKeys.faceAmount]: { type: 'double' },
  [PolicyTransactionKeys.lifePrem]: { type: 'double' },
  [PolicyTransactionKeys.targetPrem]: { type: 'double' },
  [PolicyTransactionKeys.excessPrem]: { type: 'double' },
  [PolicyTransactionKeys.annuity]: { type: 'double' },
  [PolicyTransactionKeys.policies]: { type: 'double' },
  [PolicyTransactionKeys.weightedPrem]: { type: 'double' },
  [PolicyTransactionKeys.year]: { type: 'double' },
};
