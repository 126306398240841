import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { ChangeLogsType, PERSONAL_INFO_SEARCH_KEYS_CONFIG, RegistrantKeys } from '@ag-common-lib/public-api';
import { getDateTimeLog } from '../../utils/normalize-log';

@Component({
  selector: 'ag-shr-personal-info-change-log',
  templateUrl: './personal-info-log-details.component.html',
  styleUrls: ['./personal-info-log-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalInfoLogDetailsComponent {
  @Input()
  set changeLogs(delta: Delta) {
    const logs = {};
    Object.entries(delta).forEach(data => {
      const key = data[0];
      const value = data[1];
      if (key === RegistrantKeys.lastEvalDate) {
        Object.assign(logs, { [RegistrantKeys.lastEvalDate]: getDateTimeLog(value) });
        return;
      }
      Object.assign(logs, { [key]: value });
    });

    this._changeLogs = logs;
  }
  get changeLogs(): Delta {
    return this._changeLogs;
  }
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly ChangeLogsType = ChangeLogsType;
  protected readonly personalInfoConfig = PERSONAL_INFO_SEARCH_KEYS_CONFIG;
  private _changeLogs: Delta;
}
