import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogDetailModule } from '../../change-log-detail/change-log-detail.module';
import { ChangeLogExcursionPreferencesComponent } from './change-log-excursion-preferences.component';
import { ChangeLogPipesModule } from '../../../pipes/change-log-pipes.module';

@NgModule({
  declarations: [ChangeLogExcursionPreferencesComponent],
    imports: [ CommonModule, SharedModule, ChangeLogDetailModule, ChangeLogPipesModule ],
  exports: [ChangeLogExcursionPreferencesComponent],
})
export class ChangeLogExcursionPreferencesModule {}
