export enum REGISTRATION_STATUS {
  RECORD_CREATED = 'Record Created',
  LOGIN_CREATED = 'Login Created',
  REQUIRES_APPROVAL = 'Requires Approvel',
  ID_NEEDED = 'ID Needed',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export const REGISTRATION_STATUS_LOOKUP = Object.entries(REGISTRATION_STATUS).map(([key, item]: [string, string]) => ({
  value: key,
  description: item,
}));
