import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';
import { ChangeLogDetailByTypeComponent } from './change-log-detail-by-type.component';
import { ChangeLogDetailModule } from '../change-log-detail/change-log-detail.module';

@NgModule({
  declarations: [ChangeLogDetailByTypeComponent],
  imports: [CommonModule, SharedModule, ChangeLogDetailModule, ChangeLogPipesModule],
  exports: [ChangeLogDetailByTypeComponent],
})
export class ChangeLogDetailByTypeModule {}
