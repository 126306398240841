import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';
import {
  PHONE_NUMBER_KEYS_LOOKUP,
  PhoneNumberKeys,
  SUPPORT_TYPE_LOOKUP,
  TRUE_FALSE_LOOKUP,
} from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-change-log-phone-detail',
  templateUrl: './change-log-phone.component.html',
  styleUrls: ['./change-log-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogPhoneComponent {
  @Input() log: Delta;

  protected readonly phoneKeys = [
    PhoneNumberKeys.number,
    PhoneNumberKeys.extension,
    PhoneNumberKeys.phone_type,
    PhoneNumberKeys.is_primary,
    PhoneNumberKeys.is_whatsapp,
    PhoneNumberKeys.supportType,
  ];
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly trueFalseLookup = TRUE_FALSE_LOOKUP;
  protected readonly phoneNumberLookup = PHONE_NUMBER_KEYS_LOOKUP;
  protected readonly phoneKeyWithLookup = {
    [PhoneNumberKeys.phone_type]: PHONE_NUMBER_KEYS_LOOKUP,
    [PhoneNumberKeys.is_primary]: this.trueFalseLookup,
    [PhoneNumberKeys.is_whatsapp]: this.trueFalseLookup,
    [PhoneNumberKeys.supportType]: SUPPORT_TYPE_LOOKUP,
  };
}
