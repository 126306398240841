import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import {
  ChangeLogsType,
  ConferenceKeys,
  LookupKeys,
  SELECTED_EXCURSIONS_KEYS_CONFIG,
  SelectedExcursionsKeys,
} from '@ag-common-lib/public-api';
import { map } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { getDateTimeLog, logHasKey } from '../../utils/normalize-log';
import { ChangesHistoryGridService } from '../../changes-history-grid.service';

@UntilDestroy()
@Component({
  selector: 'ag-shr-excursions-change-log',
  templateUrl: './attendee-excursions-log-details.component.html',
  styleUrls: ['./attendee-excursions-log-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendeeExcursionsLogDetailsComponent {
  @Input()
  set changeLogs(delta: Delta) {
    if (!delta) {
      this._changeLogs = delta;
      return;
    }
    // added - Array[null, {sdf: {data: {...}}}], updated - {sdf: {data: [old, new]}}
    const selectedExcursions = Array.isArray(delta) ? delta[1] : delta;

    const logs = {};
    Object.entries(selectedExcursions).forEach(data => {
      const key = data[0];
      const value = data[1];
      this.excursionKeysIndexes.push(key);
      const selectedExcursionKeys = this.selectedExcursionsKeysConfig.map(({ value }) => value);
      selectedExcursionKeys.forEach(ind => {
        const excursionInd = ind.split('.')[1];
        const logValue = value[excursionInd];
        if (!logValue) {
          return;
        }
        if (excursionInd === SelectedExcursionsKeys.bookingDate) {
          Object.assign(value, { [SelectedExcursionsKeys.bookingDate]: getDateTimeLog(value) });
          return;
        }
        Object.assign(value, { [ind]: Array.isArray(logValue) ? logValue : [logValue] });
      });

      Object.assign(logs, { [key]: value });
    });
    this._changeLogs = logs;
  }
  get changeLogs(): Delta {
    return this._changeLogs;
  }
  @Input() attendeeName: string;

  protected readonly selectedExcursionsKeysConfig = SELECTED_EXCURSIONS_KEYS_CONFIG;
  protected readonly ChangeLogsType = ChangeLogsType;
  private _changeLogs: Delta;
  protected readonly logHasKey = logHasKey;
  protected readonly SelectedExcursionsKeys = SelectedExcursionsKeys;
  excursionKeysIndexes: string[] = [];
  excursionsNameLookup = [];

  constructor(changesHistoryGridService: ChangesHistoryGridService) {
    changesHistoryGridService.conference$
      .pipe(
        map(conference => conference?.[ConferenceKeys.excursions] ?? []),
        map(excursions => {
          excursions.forEach(excursion => {
            this.excursionsNameLookup.push({
              [LookupKeys.value]: excursion.id,
              [LookupKeys.description]: excursion.name,
            });
          });
        }),
        shareReplay(1),
      )
      .subscribe();
  }
}
