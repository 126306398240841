import { clearIndexedDbPersistence, terminate } from 'firebase/firestore';

export function getCookieValue(cookieName: string): string | null {
  const match = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
  return match ? match[2] : null;
}

export async function checkAndUpdateCache(db) {
  const currentSessionVersion = getCookieValue('__session');
  const localVersion = localStorage.getItem('appVersion');

  if (!localVersion) {
    debugger;
    try {
      await terminate(db);
      await clearIndexedDbPersistence(db); // Clear Firestore cache
      localStorage.setItem('appVersion', Date.now().toString()); // Update local version
      window.location.reload(); // Reload for fresh data
    } catch (error) {
      console.error('Failed to clear cache', error);
    }
  }
}
