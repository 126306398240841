import { SupportedCollectionsKeys } from './elastic-search';

export enum ImportTransactionsToElasticKeys {
  year = 'year',
  bucket = 'bucket',
  filePath = 'filePath',
  collection = 'collection',
}

export interface ImportTransactionsToElastic {
  [ImportTransactionsToElasticKeys.year]: string;
  [ImportTransactionsToElasticKeys.bucket]: string;
  [ImportTransactionsToElasticKeys.filePath]: string;
  [ImportTransactionsToElasticKeys.collection]: SupportedCollectionsKeys;
}
