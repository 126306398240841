import { Inject, Injectable } from '@angular/core';
import { Agency, AgencyKeys, AGENCY_TYPE, BaseModelKeys, Address } from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { dateFromTimestamp, QueryParam, WhereFilterOperandKeys } from '../../public-api';
import { FIREBASE_APP } from '../injections/firebase-app';
import { DataService } from './data.service';
import { firstValueFrom, map, Observable, of, shareReplay, skip } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { normalizeAddressesFromFirestore } from '../utils/address.util';

@Injectable({
  providedIn: 'root',
})
export class AgencyService extends DataService<Agency> {
  private readonly agenciesCollectionPath = 'agencies';
  readonly agencies$: Observable<Agency[]>;
  readonly mgas$: Observable<Agency[]>;

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, AgencyService.fromFirestore);
    super.setCollection(this.agenciesCollectionPath);
    this.agencies$ = this.getList([], { sortField: AgencyKeys.agencyId }).pipe(shareReplay(1));
    this.mgas$ = this.getList([new QueryParam(AgencyKeys.agencyType, WhereFilterOperandKeys.equal, AGENCY_TYPE.MGA)], {
      sortField: AgencyKeys.agencyId,
    }).pipe(shareReplay(1));
  }

  static readonly fromFirestore = (data): Agency => {
    if (!!data?.relationshipWithAG) {
      data.relationshipWithAG.initiallyMet = dateFromTimestamp(data?.relationshipWithAG?.initiallyMet);
      data.relationshipWithAG.contractedDate = dateFromTimestamp(data?.relationshipWithAG?.contractedDate);
    }

    data[AgencyKeys.stateLicenses]?.forEach(stateLicense => {
      stateLicense.expiration_date = dateFromTimestamp(stateLicense.expiration_date);
      stateLicense.expiration = dateFromTimestamp(stateLicense.expiration);
    });

    data[AgencyKeys.carriers]?.forEach(carrier => {
      carrier.first_contracted = dateFromTimestamp(carrier.first_contracted);
    });

    data[AgencyKeys.socials] = data[AgencyKeys.socials] ?? [];
    data[AgencyKeys.websites] = data[AgencyKeys.websites] ?? [];
    data[AgencyKeys.addresses] = normalizeAddressesFromFirestore(data?.[AgencyKeys.addresses]);

    return Object.assign({}, data);
  };

  getAgencyCustomStore = () => {
    const store = new CustomStore({
      key: BaseModelKeys.dbId,
      byKey: key => this.getById(key),
      load: () => {
        return firstValueFrom(this.agencies$);
      },
    });

    // TODO investigate how to update store dynamic
    // this.agencies$.pipe(skip(1)).subscribe(agencies => {
    //   debugger;
    //   store.load();
    // });

    return store;
  };

  getMGACustomStore = () => {
    const store = new CustomStore({
      key: BaseModelKeys.dbId,
      byKey: key => this.getById(key),
      load: () => {
        return firstValueFrom(this.mgas$);
      },
    });

    // TODO investigate how to update store dynamic

    return store;
  };

  getAgencyByAgencyId(id: string): Promise<Agency> {
    if (!id) {
      return Promise.resolve(null);
    }
    return this.getAllByValue([new QueryParam('agency_id', WhereFilterOperandKeys.equal, id)]).then(agencies => {
      if (!agencies?.length) {
        return null;
      }

      if (agencies?.length > 1) {
        console.error('More than 1 agency found with this agency id');
        return null;
      }

      return agencies[0];
    });
  }

  getAgenciesByRMDId(agentId: string) {
    if (!agentId) {
      return of([]);
    }

    const qp = [new QueryParam(AgencyKeys.rmds, WhereFilterOperandKeys.arrayContains, agentId)];

    return this.getList(qp);
  }

  getAgenciesIdsByRMDId(agentId: string) {
    return this.getAgenciesByRMDId(agentId).pipe(
      map(agencies => {
        return agencies?.map(agency => agency?.[AgencyKeys.agencyId])?.filter(Boolean);
      }),
    );
  }

  getMGAAgencies(sortField: string): Promise<Agency[]> {
    return this.getAllByValue(
      [new QueryParam('agency_type', WhereFilterOperandKeys.equal, AGENCY_TYPE.MGA)],
      null,
      sortField,
    );
  }

  getAgencyDisplayValue = agencyDbId => {
    return this.getById(agencyDbId).then(agency => {
      return [agency?.[AgencyKeys.agencyId], agency?.[AgencyKeys.name]].filter(Boolean).join(' - ');
    });
  };

  updateFields(documentId: string, data: Partial<Agency>): Promise<Agency> {
    return super.updateFields(documentId, data);
  }
}
