import { Pipe, PipeTransform } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { ChangeLogsConfig } from '@ag-common-lib/lib/models/registration/change-logs.model';

@Pipe({
  name: 'logsByKey',
})
export class ChangeLogDiffByKeysPipe implements PipeTransform {
  transform(logs: Delta, config: ChangeLogsConfig[]): Delta {
    if (!config?.length) {
      console.log('No Config provided!');
      return null;
    }

    const keysSet = new Set(config.map(({ value }) => value));
    const logsVisible = Object.keys(logs).filter(key => keysSet.has(key));

    return !!logsVisible?.length ? logs : null;
  }
}
