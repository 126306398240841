<h5>
  Guests Registration <ng-container *ngIf="guestName">for {{ guestName }}</ng-container>
</h5>

<ng-container
  *var="[changeLogs?.[AssociationKeys.firstName], changeLogs?.[AssociationKeys.lastName]] | fullName as guestFullName"
>
  <ng-container [ngSwitch]="action">
    <ng-container *ngSwitchCase="DocumentWriteAction.create">
      <p>
        Guests Registration was created <ng-container *ngIf="guestFullName">for {{ guestFullName }}</ng-container>
      </p>
      <ng-container *ngTemplateOutlet="detailsContainer; context: { log: changeLogs }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DocumentWriteAction.remove">
      <p>
        Guests Registration was removed <ng-container *ngIf="guestFullName">for {{ guestFullName }}</ng-container>
      </p>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="detailsContainer; context: { log: changeLogs }"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #detailsContainer let-log="log">
  <ag-shr-change-log-detail-by-type
    *ngIf="!!log"
    [keysConfig]="guestsConfig"
    [changeLogs]="log"
  ></ag-shr-change-log-detail-by-type>
</ng-template>
