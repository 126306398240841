import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogDetailModule } from '../change-log-detail/change-log-detail.module';
import { AttendeeGuestsLogDetailsComponent } from './attendee-guests-log-details.component';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';
import { ChangeLogDetailByTypeModule } from '../change-log-detail-by-type/change-log-detail-by-type.module';
import { AttendeeFlightInfoLogDetailsModule } from '../attendee-flight-info-log-details/attendee-flight-info-log-details.module';
import { AttendeeExcursionsLogDetailsModule } from '../excursions-log-details/attendee-excursions-log-details.module';

@NgModule({
  declarations: [AttendeeGuestsLogDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    ChangeLogDetailModule,
    ChangeLogPipesModule,
    ChangeLogDetailByTypeModule,
    AttendeeFlightInfoLogDetailsModule,
    AttendeeExcursionsLogDetailsModule,
  ],
  exports: [AttendeeGuestsLogDetailsComponent],
})
export class AttendeeGuestsLogDetailsModule {}
