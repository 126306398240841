<ng-container *ngFor="let ind of preferencesLogIndexes">
  <h6>{{ind}}</h6>
  <ng-container *ngFor="let item of preferencesLookup">
    <ng-container *var="item.value | changeLogChildKey as key">
      <ag-shr-change-log-detail
        *ngIf="logHasKey(key, log[ind])"
        [item]="item.description"
        [changeLog]="log[ind]?.[key]"
        [dataType]="item?.dataType ?? null"
        [activeLookupName]="item?.activeLookupName ?? null"
        [valueLookup]="item?.valueLookup ?? null"
      ></ag-shr-change-log-detail>
    </ng-container>
  </ng-container>
</ng-container>
