import { LookupKeys } from '../../models/crm/lookup.model';

export enum ChangeSourceType {
  regularPortal = 'regularPortal',
  import = 'import',
  mergeTool = 'mergeTool',
  regularArm = 'regularArm',
  impersonate = 'impersonate',
}

export const CHANGE_SOURCE_TYPE_LOOKUP = [
  {
    [LookupKeys.value]: ChangeSourceType.import,
    [LookupKeys.description]: 'Import',
  },
  {
    [LookupKeys.value]: ChangeSourceType.mergeTool,
    [LookupKeys.description]: 'Merge Tool',
  },
  {
    [LookupKeys.value]: ChangeSourceType.regularArm,
    [LookupKeys.description]: 'ARM UI',
  },
  {
    [LookupKeys.value]: ChangeSourceType.regularPortal,
    [LookupKeys.description]: 'Portal',
  },
  {
    [LookupKeys.value]: ChangeSourceType.impersonate,
    [LookupKeys.description]: 'Portal via Impersonate',
  },
];
