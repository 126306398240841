import { Agent, AgentKeys } from '@ag-common-lib/public-api';

export function getAgentFullName(agent: Agent) {
  let fullName = [agent?.[AgentKeys.p_agent_first_name], agent?.[AgentKeys.p_agent_last_name]]
    .filter(Boolean)
    .join(' ');

  const pEmail = agent?.[AgentKeys.p_email];

  if (pEmail) {
    fullName += ` (${pEmail})`;
  }

  return fullName;
}
