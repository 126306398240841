import { Pipe, PipeTransform } from '@angular/core';
import { getDateTimeLog } from '../utils/normalize-log';

@Pipe({
  name: 'changeLogTimeStampToLocalDate',
})
export class ChangeLogTimeStampToDatePipe implements PipeTransform {
  transform(timeStamp: string): string {
    if (!timeStamp) {
      return '';
    }
    return getDateTimeLog(timeStamp);
  }
}
