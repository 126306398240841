import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogDetailModule } from '../change-log-detail/change-log-detail.module';
import { AttendeeFlightInfoLogDetailsComponent } from './attendee-flight-info-log-details.component';
import { ChangeLogFlightBookingInfoModule } from './booking-info/change-log-flight-booking-info.module';
import { ChangeLogRequestOutcomeModule } from '../change-log-detail/request-outcome/change-log-request-outcome.module';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';
import { ChangeLogDetailByTypeModule } from '../change-log-detail-by-type/change-log-detail-by-type.module';

@NgModule({
  declarations: [AttendeeFlightInfoLogDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    ChangeLogDetailModule,
    ChangeLogFlightBookingInfoModule,
    ChangeLogRequestOutcomeModule,
    ChangeLogPipesModule,
    ChangeLogDetailByTypeModule,
  ],
  exports: [AttendeeFlightInfoLogDetailsComponent],
})
export class AttendeeFlightInfoLogDetailsModule {}
