import { BaseModel } from '@ag-common-lib/public-api';
import { CommonFireStoreDao, FetchOptions, QueryParam } from '../dao/CommonFireStoreDao.dao';
import { FirebaseApp } from 'firebase/app';
import { Observable } from 'rxjs';
import { DocumentReference, DocumentSnapshot, QuerySnapshot } from 'firebase/firestore';

export class DataService<T extends BaseModel> {
  collection: string;

  readonly fsDao: CommonFireStoreDao<T>;

  constructor(
    fireBaseApp: FirebaseApp,
    fromFirestore: (data: Partial<T>) => T = null,
    toFirestore: (item: T) => T = null,
  ) {
    this.fsDao = new CommonFireStoreDao<T>(fireBaseApp, fromFirestore, toFirestore);
  }

  getDocDbId(): string {
    return this.fsDao.getDocDbId(this.collection);
  }

  public getDocReference(id: string): DocumentReference<T> {
    return this.fsDao.getDocReference(this.collection, id);
  }

  public getDocument(id: string): Observable<DocumentSnapshot<T>> {
    return this.fsDao.getDocument(this.collection, id);
  }

  public getById(id: any): Promise<T> {
    return this.fsDao.getById(this.collection, id);
  }

  public getAllByValue(qp: QueryParam[], docsLimit: number = 0, sortField?: string): Promise<T[]> {
    return this.fsDao.getAllByQValue(this.collection, qp, docsLimit, sortField);
  }

  public getList(qp: QueryParam[] = [], fetchOptions?: FetchOptions): Observable<T[]> {
    return this.fsDao.getList(this.collection, qp, fetchOptions);
  }

  getListChanges(qp: QueryParam[] = [], fetchOptions?: FetchOptions): Observable<QuerySnapshot<T[]>> {
    return this.fsDao.getCollectionSnapshot(this.collection, qp);
  }

  public getAll(sortField?: string): Promise<T[]> {
    return this.fsDao.getAll(this.collection, sortField);
  }

  public create(value: T, dbId?: string) {
    return this.fsDao.create(value, this.collection, dbId);
  }

  public createWithId(value: T) {
    return this.fsDao.createWithId(value, value.dbId, this.collection);
  }

  /**
   * @deprecated Use updateFields instead // TODO rename to set
   */
  public update(value: T) {
    return this.fsDao.update(value, value.dbId, this.collection);
  }

  public updateFields(documentId: string, data: Partial<T>, skipListUpdate?: boolean) {
    return this.fsDao.updateFields(data, documentId, this.collection, skipListUpdate);
  }

  public delete(id: any) {
    return this.fsDao.delete(id, this.collection);
  }

  setCollection(collection: string) {
    this.collection = collection;
  }
}
