import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeLogCaptionByIndex',
})
export class ChangeLogCaptionByIndexPipe implements PipeTransform {
  transform(caption: string, ind: number): string {
    const typeByIndex = ind.toString() === '1' ? 'added' : ind.toString() === '_1' ? 'removed' : 'updated';
    return `${caption} was ${typeByIndex}.`;
  }
}
