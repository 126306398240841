import { Pipe, PipeTransform } from '@angular/core';
import { ConferenceKeys } from '@ag-common-lib/public-api';
import { ConferenceService } from 'ag-common-svc/public-api';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'conferenceNameByDbId', pure: false })
export class ConferenceNameByDbIdPipe implements PipeTransform {
  private conferenceCache: { [id: string]: string } = {};

  constructor(private conferenceService: ConferenceService) {}

  transform(conferenceDbId: string): Observable<string> {
    if (this.conferenceCache[conferenceDbId]) {
      // Return cached value if it exists
      return of(this.conferenceCache[conferenceDbId]);
    }

    // Fetch value from the service and cache it
    return this.conferenceService.getDocument(conferenceDbId).pipe(
      map(conferenceSnapshot => {
        const title = conferenceSnapshot.exists()
          ? conferenceSnapshot.data()?.[ConferenceKeys.eventName]
          : 'Unknown Conference';
        this.conferenceCache[conferenceDbId] = title; // Cache the result
        return title;
      }),
      catchError(() => {
        const fallbackTitle = 'Unknown Conference';
        this.conferenceCache[conferenceDbId] = fallbackTitle;
        return of(fallbackTitle);
      }),
    );
  }

  getCache(): { [id: string]: string } {
    return this.conferenceCache;
  }
}
