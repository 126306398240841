import { LookupId } from '../../lists/lookups.enum';
import { LookupKeys } from '../crm/lookup.model';

export enum WebsiteKeys {
  id = 'id',
  url = 'url',
  websiteType = 'website_type',
}

export class Website {
  [WebsiteKeys.id]?: string;
  [WebsiteKeys.url]?: string;
  [WebsiteKeys.websiteType]?: LookupId<'AgencyWebsiteTypes'> | LookupId<'WebsiteTypes'>;
}

export const WEBSITE_KEYS_LOOKUP = [
  { [LookupKeys.value]: WebsiteKeys.url, [LookupKeys.description]: 'URL' },
  { [LookupKeys.value]: WebsiteKeys.websiteType, [LookupKeys.description]: 'Type' },
];
