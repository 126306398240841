<h5>Hotel Reservation</h5>

<ng-container *ngFor="let item of hotelReservationKeysLookup">
  <ng-container *var="item.value | changeLogChildKey as key">
    <ng-container *ngIf="changeLogs[key] as log">
      <ng-container [ngSwitch]="key">
        <div class="pl-4 ps-4" *ngSwitchCase="HotelReservationKeys.bookingInfo">
          <h6>Booking Info</h6>
          <ag-shr-change-log-booking-info [log]="log"></ag-shr-change-log-booking-info>
        </div>

        <div class="pl-4 ps-4" *ngSwitchDefault>
          <ag-shr-change-log-detail-by-type
            [keysConfig]="[item]"
            [changeLogs]="changeLogs"
          ></ag-shr-change-log-detail-by-type>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<!-- OLD implementation: -->
<!--<div class="md-4">-->
<!--  <ng-container *ngFor="let item of hotelReservationKeysLookup">-->
<!--    <ng-container *var="item.value | changeLogChildKey as key">-->
<!--      <ng-container *ngIf="changeLogs[key] as log">-->
<!--        <ng-container [ngSwitch]="key">-->
<!--          <div class="pl-4 ps-4" *ngSwitchCase="HotelReservationKeys.requestDifferBookingDatesOutcome">-->
<!--            <h6>Request Differ Booking Dates Outcome</h6>-->
<!--            <ag-shr-change-log-request-outcome-detail [log]="log[0]"></ag-shr-change-log-request-outcome-detail>-->
<!--          </div>-->

<!--          <div class="pl-4 ps-4" *ngSwitchCase="HotelReservationKeys.additionalRoomRequestOutcome">-->
<!--            <h6>Additional Room Request Outcome</h6>-->
<!--            <ag-shr-change-log-request-outcome-detail [log]="log[0]"></ag-shr-change-log-request-outcome-detail>-->
<!--          </div>-->

<!--          <div class="pl-4 ps-4" *ngSwitchCase="HotelReservationKeys.additionalRoomRequested">-->
<!--            <ag-shr-change-log-detail-->
<!--              *ngIf="!!log?.length"-->
<!--              [item]="item.description"-->
<!--              [changeLog]="log"-->
<!--              [dataType]="item?.dataType ?? null"-->
<!--              [activeLookupName]="item?.activeLookupName ?? null"-->
<!--              [valueLookup]="item?.valueLookup ?? null"-->
<!--            ></ag-shr-change-log-detail>-->
<!--          </div>-->

<!--          &lt;!&ndash;          <div class="pl-4 ps-4" *ngSwitchCase="HotelReservationKeys.bookingInfo">&ndash;&gt;-->
<!--          &lt;!&ndash;            <h6>Booking Info</h6>&ndash;&gt;-->
<!--          &lt;!&ndash;            <ag-shr-change-log-booking-info [log]="log"></ag-shr-change-log-booking-info>&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->

<!--          <div class="pl-4 ps-4" *ngSwitchDefault>-->
<!--            <ng-container *ngIf="log?.length === 2; else removedLogData">-->
<!--              <ng-container-->
<!--                *ngTemplateOutlet="-->
<!--                  detailsContainer;-->
<!--                  context: {-->
<!--                    item: item,-->
<!--                    log: log,-->
<!--                    type: ChangeLogsType.changed-->
<!--                  }-->
<!--                "-->
<!--              ></ng-container>-->
<!--            </ng-container>-->

<!--            <ng-template #removedLogData>-->
<!--              <ng-container *ngIf="log?.length === 3; else addedLogData">-->
<!--                <ng-container-->
<!--                  *ngTemplateOutlet="-->
<!--                    detailsContainer;-->
<!--                    context: {-->
<!--                      item: item,-->
<!--                      log: log[0],-->
<!--                      type: ChangeLogsType.removed-->
<!--                    }-->
<!--                  "-->
<!--                ></ng-container>-->
<!--              </ng-container>-->

<!--              <ng-template #addedLogData>-->
<!--                <ng-container-->
<!--                  *ngTemplateOutlet="-->
<!--                    detailsContainer;-->
<!--                    context: {-->
<!--                      item: item,-->
<!--                      log: log[0],-->
<!--                      type: ChangeLogsType.added-->
<!--                    }-->
<!--                  "-->
<!--                ></ng-container>-->
<!--              </ng-template>-->
<!--            </ng-template>-->
<!--          </div>-->
<!--        </ng-container>-->
<!--      </ng-container>-->
<!--    </ng-container>-->
<!--  </ng-container>-->
<!--</div>-->

<!--<ng-template #detailsContainer let-item="item" let-log="log" let-type="type">-->
<!--  <ag-shr-change-log-detail-->
<!--    *ngIf="log as itemLog"-->
<!--    [item]="item?.description"-->
<!--    [type]="type"-->
<!--    [changeLog]="itemLog"-->
<!--    [dataType]="item?.dataType ?? null"-->
<!--    [activeLookupName]="item?.activeLookupName ?? null"-->
<!--    [valueLookup]="item?.valueLookup ?? null"-->
<!--  ></ag-shr-change-log-detail>-->
<!--</ng-template>-->
