<h5>Details</h5>

<div class="md-4">
  <ng-container *ngFor="let item of agentInfoConfig">
    <ng-container *var="item.value | changeLogChildKey as key">
      <ng-container *ngIf="changeLogs?.[key] as log">
        <ng-container [ngSwitch]="key">
          <div class="pl-4 ps-4" *ngSwitchCase="AgentKeys.websites">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="websitesKeysLookup"
              [logs]="log"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4" *ngSwitchCase="AgentKeys.socials">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="socialsKeysLookup"
              [logs]="log"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4" *ngSwitchCase="AgentKeys.addresses">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="addressesKeysLookup"
              [logs]="log"
              [datType]="item.dataType"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4" *ngSwitchCase="AgentKeys.personal_goals">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="goalsKeysLookup"
              [logs]="log"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4" *ngSwitchCase="AgentKeys.conference_goals">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="goalsKeysLookup"
              [logs]="log"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4" *ngSwitchCase="AgentKeys.manager_goals">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="goalsKeysLookup"
              [logs]="log"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4" *ngSwitchCase="AgentKeys.phone_numbers">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="phoneNumbersKeysLookup"
              [datType]="item.dataType"
              [logs]="log"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4" *ngSwitchCase="AgentKeys.approve_deny_reasons">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="approveDenyReasonKeysLookup"
              [logs]="log"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4" *ngSwitchCase="AgentKeys.agent_carriers">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="agentCarrierKeysLookup"
              [logs]="log"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4" *ngSwitchCase="AgentKeys.state_licenses">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="agentStateLicensesKeysLookup"
              [logs]="log"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4" *ngSwitchCase="AgentKeys.favorites">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="favoritesKeysLookup"
              [logs]="log"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4" *ngSwitchCase="AgentKeys.campaigns">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="campaignsKeysLookup"
              [logs]="[log]"
            ></ag-shr-agent-change-log-details-by-type>
          </div>
          <!--            [AgentKeys.approve_deny_reasons]?: ApproveDenyReason[] = [];-->
          <!--            [AgentKeys.campaigns]?: AgentCampaigns;-->
          <div class="pl-4 ps-4" *ngSwitchDefault>
            <ag-shr-change-log-detail-by-type
              *ngIf="!!log?.length"
              [keysConfig]="[item]"
              [changeLogs]="changeLogs"
            ></ag-shr-change-log-detail-by-type>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
